import {
  BlockStack,
  Box,
  Button,
  InlineStack,
  Text,
  useBreakpoints,
} from '@shopify/polaris';
import { ExternalIcon, XIcon } from '@shopify/polaris-icons';
import posthog from 'posthog-js';
import styles from './FancyBanner.module.scss';

type FancyBanner = {
  title: string;
  description: string;
  rightImage: string;
  ctaText: string;
  ctaLink?: string;
  onClose: () => void;
  hideAnimations?: boolean;
  tracking?: string;
};

export const FancyBanner = ({
  title,
  description,
  ctaText,
  rightImage,
  onClose,
  ctaLink,
  hideAnimations = false,
  tracking = 'default',
}: FancyBanner) => {
  const { mdUp } = useBreakpoints();

  return (
    <a
      href={ctaLink}
      onClick={() => posthog.capture('Email banner body clicked')}
      target='_blank'
      rel='noreferrer'
    >
      <div
        id={`${tracking}-fancy-banner`}
        style={{ maxWidth: 'unset', padding: '0.75rem 1rem', margin: 0 }}
      >
        <div
          style={{
            borderRadius: 'var(--p-border-radius-200)',
            width: '100%',
            backgroundColor: '#2C3940',
            backgroundImage: 'url("/static/img/BannerPattern.png")',
            backgroundPosition: 'right',
            backgroundRepeat: 'no-repeat',
            padding: 'var(--p-space-600)',
            paddingLeft: 'var(--p-space-800)',
            overflow: 'hidden',
            position: 'relative',
            boxShadow: 'var(--p-shadow-300)',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'start',
              minHeight: '100px',
              maxWidth: '99%',
              gap: '20px',
            }}
          >
            {!hideAnimations && (
              <div className={styles.night}>
                <div className={styles.shootingStar} />
                <div className={styles.shootingStar} />
                <div className={styles.shootingStar} />
                <div className={styles.shootingStar} />
              </div>
            )}
            <BlockStack gap='100'>
              <Text variant='headingMd' as='h2' tone='text-inverse'>
                {title}
              </Text>
              <Box maxWidth='43em'>
                <Text as='p' tone='text-inverse'>
                  {description}
                </Text>
              </Box>
            </BlockStack>
            <InlineStack blockAlign='center' gap='500'>
              <Button
                target='_blank'
                url={ctaLink}
                onClick={() => {
                  posthog.capture('Email banner CTA clicked');
                }}
                id={`${tracking}-fancy-banner-cta`}
                icon={ExternalIcon}
              >
                {ctaText}
              </Button>
            </InlineStack>

            {mdUp && (
              <div
                style={{
                  position: 'absolute',
                  right: '60px',
                  top: '25px',
                  transform: 'rotate(0deg)',
                }}
              >
                <img
                  src={rightImage}
                  alt='fancy-banner-card'
                  className={styles.fancyBannerCard}
                />
              </div>
            )}

            <Box position='absolute' insetInlineEnd='300' insetBlockStart='300'>
              <button
                type='button'
                style={{
                  background: 'none',
                  border: 'none',
                  cursor: 'pointer',
                  padding: '8px 8px 20px 20px',
                }}
                onClick={e => {
                  e.preventDefault();
                  posthog.capture('Email banner closed');
                  onClose();
                }}
                id={`${tracking}-fancy-banner-dismiss`}
              >
                <XIcon width='20px' height='20px' fill='white' />
              </button>
            </Box>
          </div>
        </div>
      </div>
    </a>
  );
};
