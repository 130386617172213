import styled from 'styled-components';
import space from 'barn/tokens/space';
import { responsiveScreenBreakpoint } from 'barn/tokens/misc';

interface GridProps {
  templateColumns?: string;
  templateRows?: string;
  autoRows?: string;
  justify?: string;
  gap: number;
  tag?: string;
  height?: string;
  responsive?: boolean;
  align?: string;
  children: React.ReactNode;
}

const StyledGrid = styled.div<{ styles: AnyObject }>`
  ${({ styles }) => styles}
`;

const Grid: React.FunctionComponent<GridProps> = ({
  templateColumns = '',
  templateRows = '',
  autoRows = '',
  gap,
  justify = 'flex-start',
  tag = 'div',
  height,
  responsive = false,
  align,
  ...props
}) => {
  const styles = {
    display: 'grid',
    gridGap: space.margin[gap],
    gridTemplateColumns: templateColumns,
    gridTemplateRows: templateRows,
    gridAutoRows: autoRows,
    justifyContent: justify,
    alignItems: align,
    height,
  };

  // The idea here is that on smaller screens, we want elements
  // to be stacked vertically taking their own height, where
  // neither flex or grid would be much useful
  if (responsive) {
    styles[`@media (max-width: ${responsiveScreenBreakpoint})`] = {
      gridTemplateColumns: '1fr',
      gridTemplateRows: 'auto',
    };
  }

  /* eslint-disable react/destructuring-assignment */
  return (
    <StyledGrid
      styles={styles}
      {...props}
      as={tag as keyof JSX.IntrinsicElements}
    />
  );
};

export { Grid };
