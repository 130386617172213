import React from 'react';
import posthog from 'posthog-js';
import { useNewCampaign } from 'src/hooks/use-new-campaign';
import { useGlobalState } from 'src/hooks/use-global-state';
import { SendType } from 'src/modules/campaign-creator/models';
import { Button } from '@shopify/polaris';
import useTranslation from 'next-translate/useTranslation';

interface Props {
  label?: string;
  sendType?: SendType;
  iconOnly?: Boolean;
}

const NewCampaign = ({
  label,
  iconOnly = false,
  sendType = SendType.NOW,
}: Props) => {
  const { t } = useTranslation('common');

  const { startNewCampaign } = useNewCampaign();

  // Wait for the consumption API to complete before allowing new campaign creation
  const {
    state: { consumption },
  } = useGlobalState('settings');

  return (
    <Button
      tone='success'
      onClick={() => {
        startNewCampaign({ sendType });
        posthog.capture('New Campaign');
      }}
      id='new-campaign-btn'
      loading={consumption.isFetching}
      variant='primary'
    >
      {!iconOnly && (label || t('New Campaign'))}
    </Button>
  );
};

export default NewCampaign;
