import FullLogo from 'barn/icons/FullLogo';
import Logo from 'barn/icons/Logo';
import { Icon } from '@shopify/polaris';
import { XIcon } from '@shopify/polaris-icons';
import { useGlobalState } from 'src/hooks/use-global-state';
import StyledSidebar, {
  StyledLogoHolder,
  StyledCTAHolder,
  CloseButton,
} from './StyledSidebar';

interface SidebarProps {
  cta: any;
  children: React.ReactNode;
}
const Sidebar: React.FunctionComponent<SidebarProps> = ({ cta, children }) => {
  const {
    state: { isSidebarOpen, isSmallScreen },
    actions: { toggleSidebar },
  } = useGlobalState('common');

  return (
    <StyledSidebar data-test='sidebar' isSidebarOpen={isSidebarOpen}>
      <StyledLogoHolder isSmallScreen={isSmallScreen}>
        {isSmallScreen ? <Logo size='mobile' /> : <FullLogo />}
        {isSmallScreen && isSidebarOpen ? (
          <CloseButton onClick={toggleSidebar} type='button'>
            <Icon source={XIcon} />
          </CloseButton>
        ) : null}
      </StyledLogoHolder>

      {isSidebarOpen && <StyledCTAHolder>{cta}</StyledCTAHolder>}
      {children}
    </StyledSidebar>
  );
};

export default Sidebar;
