import styled from 'styled-components';
import { fontSizes } from 'barn/tokens/typography';
import tokens from 'barn/tokens';

const StyledTextarea = styled.textarea<{ highlightColor?: string }>`
  box-sizing: border-box;
  outline: none;
  border-radius: ${tokens.radii[4]};
  border: 1px solid ${tokens.colors.greys[5]};
  background: ${tokens.colors.greys[8]};
  padding: ${tokens.space.padding[3]} ${tokens.space.padding[4]};
  height: 36px;
  font-size: ${fontSizes[2]};
  font-family: inherit;
  color: ${tokens.colors.greys[0]};
  max-width: 100ch;

  &:hover,
  &:focus {
    border-color: ${({ highlightColor }) =>
      highlightColor || tokens.colors.blues[5]};
  }

  &:focus {
    /*  
       Using box-shadow instead of outline 
       since outline won't have border-radius in Safari and old Edge (v18)
      */
    box-shadow: inset 0 0 0 1px
      ${({ highlightColor }) => highlightColor || tokens.colors.blues[5]};
  }

  &[disabled],
  &:disabled {
    color: ${tokens.colors.greys[5]};
    opacity: 0.3;

    &:hover {
      border-color: ${tokens.colors.greys[5]};
    }
  }

  &.error {
    background-color: ${tokens.colors.reds[9]};
    border-color: ${tokens.colors.reds[5]};
  }

  &.error:focus {
    box-shadow: inset 0 0 0 1px ${tokens.colors.reds[5]};
  }

  &.inactive {
    background: ${tokens.colors.greys[6]};
    border-color: ${tokens.colors.greys[6]};
    opacity: 1;
    color: ${tokens.colors.greys[3]};
  }
`;

const StyledEmojiTextarea = styled(StyledTextarea)`
  display: block;
  padding-right: 74px;
  width: ${(props: { fullWidth: boolean }) =>
    `${props.fullWidth ? '100%' : 'max-content'}`};
  min-height: 116px;
  resize: none;
`;

export const StyledTextareaWrapper = styled.div`
  position: relative;
  width: ${(props: { fullWidth: boolean }) =>
    `${props.fullWidth ? '100%' : 'max-content'}`};
`;

export const StyledEmojiContainer = styled.div`
  display: flex;
  align-items: flex-start;
  position: absolute;
  top: 8px;
  right: 8px;
  height: 100%;
`;

export default StyledEmojiTextarea;
