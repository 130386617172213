import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { OnboardingStatus } from 'src/modules/email-onboarding/models/constants';
import { useEmailOnboarding } from 'src/modules/email-onboarding/utils/use-email-onboarding';
import { useGlobalState } from './use-global-state';

export const useEmailActivated = () => {
  const [isEmailActivated, setIsEmailActivated] = useState(false);
  const router = useRouter();
  const { isCheckingOnboardingStatus } = useEmailOnboarding();
  const {
    state: { onboardingStatus, subAccountId },
  } = useGlobalState('email');
  const { actions: pricingV2 } = useGlobalState('pricingV2');

  const checkInCompleteOnboarding = async () => {
    if (subAccountId && onboardingStatus) {
      const allStatuses = Object.values(onboardingStatus);
      if (
        allStatuses.every(status => status === OnboardingStatus.NOT_STARTED) ||
        !pricingV2.hasEmailPlan()
      ) {
        setIsEmailActivated(false);
      } else {
        setIsEmailActivated(true);
      }
    } else {
      setIsEmailActivated(false);
    }
  };

  useEffect(() => {
    if (isCheckingOnboardingStatus) return;

    if (!isCheckingOnboardingStatus) {
      checkInCompleteOnboarding();
    }
  }, [
    subAccountId,
    isCheckingOnboardingStatus,
    onboardingStatus,
    router.query,
  ]);

  return isEmailActivated;
};
