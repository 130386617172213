import {
  DataPresentationIcon,
  DesktopIcon,
  EmailIcon,
  HomeIcon,
  NotificationIcon,
} from '@shopify/polaris-icons';

export type Route = {
  path: string;
  name: string;
  key: string;
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  subRoutes: Route[];
  parent?: string;
  isNewBadgeVisible?: boolean;
};

export const routes: Route[] = [
  {
    name: 'Home',
    path: '/',
    icon: HomeIcon,
    key: 'sidebar.home',
    isNewBadgeVisible: false,
    subRoutes: [],
  },
  {
    path: '/webpush',
    name: 'Webpush',
    key: 'sidebar.webpush',
    icon: NotificationIcon,
    subRoutes: [
      {
        path: '/campaigns',
        name: 'Campaigns',
        key: 'sidebar.webpush.subroute.campaigns',
        icon: null,
        subRoutes: [],
        parent: 'Webpush',
      },
      {
        path: '/automation',
        name: 'Automations',
        key: 'sidebar.webpush.subroute.automation',
        icon: null,
        subRoutes: [],
        parent: 'Webpush',
      },
      {
        path: '/subscribers',
        name: 'Subscribers',
        key: 'sidebar.webpush.subroute.subscribers',
        icon: null,
        parent: 'Webpush',
        subRoutes: [],
      },
      {
        name: 'Integrations',
        icon: null,
        key: 'sidebar.webpush.subroute.integrations',
        path: '/integrations',
        subRoutes: [],
        parent: 'Webpush',
      },
      {
        name: 'Settings',
        icon: null,
        key: 'sidebar.webpush.subroute.settings',
        path: '/settings',
        subRoutes: [],
        parent: 'Webpush',
      },
      {
        name: 'Activity Feed',
        icon: null,
        key: 'sidebar.webpush.subroute.activityFeed',
        path: '/activity',
        subRoutes: [],
        parent: 'Webpush',
      },
    ],
  },
  {
    name: 'Email',
    path: '/email',
    icon: EmailIcon,
    key: 'sidebar.email',
    isNewBadgeVisible: true,
    subRoutes: [],
  },
  {
    name: 'Optins',
    icon: DesktopIcon,
    key: 'sidebar.optins',
    path: '/optins',
    subRoutes: [],
  },
  {
    name: 'Analytics',
    path: '/ecommerce',
    icon: DataPresentationIcon,
    key: 'sidebar.analytics',
    isNewBadgeVisible: true,
    subRoutes: [
      {
        path: '/ecommerce/products',
        name: 'Products',
        key: 'sidebar.analytics.subroute.products',
        icon: null,
        subRoutes: [],
        parent: 'Analytics',
      },
      {
        path: '/ecommerce/revenue',
        name: 'Revenue',
        key: 'sidebar.analytics.subroute.revenue',
        icon: null,
        subRoutes: [],
        parent: 'Analytics',
      },
      {
        icon: null,
        key: 'sidebar.analytics.subroute.retention',
        name: 'Retention',
        path: '/ecommerce/retention',
        subRoutes: [],
        parent: 'Analytics',
      },
      {
        name: 'Settings',
        icon: null,
        key: 'sidebar.analytics.subroute.settings',
        path: '/ecommerce/settings',
        subRoutes: [],
        parent: 'Analytics',
      },
    ],
  },
];

export const emailRoutes: Route[] = [
  {
    path: '/email?path=marketing-campaign/list',
    name: 'Campaigns',
    key: 'sidebar.email.subroute.campaigns',
    icon: null,
    subRoutes: [],
    parent: 'Email',
  },
  {
    path: '/email?path=automation/automations',
    name: 'Automations',
    key: 'sidebar.email.subroute.automation',
    icon: null,
    subRoutes: [],
    parent: 'Email',
  },
  {
    icon: null,
    key: 'sidebar.email.subroute.segments',
    name: 'Segments',
    path: '/email?path=contact/segment',
    subRoutes: [],
    parent: 'Email',
  },
  {
    name: 'Settings',
    icon: null,
    key: 'sidebar.email.subroute.settings',
    path: '/email?path=advanced/config',
    subRoutes: [],
    parent: 'Email',
  },
];
