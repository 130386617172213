import { v1 as uuidv1 } from 'uuid';
import colors from 'barn/tokens/colors';

const BackArrow = ({ color = colors.greys[2], style = {} }) => {
  const id = uuidv1();

  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' style={style}>
      <mask
        id={`maskBackArrow${id}`}
        mask-type='alpha'
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='20'
        height='20'
      >
        <rect
          className='iconColor'
          x='20'
          y='20'
          width='20'
          height='20'
          transform='rotate(180 20 20)'
          fill={color}
        />
      </mask>
      <g mask={`url(#maskBackArrow${id})`}>
        <path
          className='iconColor'
          fillRule='evenodd'
          clipRule='evenodd'
          d='M15.25 8.75011H6.56065L9.03036 6.28041C9.3236 5.98717 9.3236 5.51318 9.03036 5.21993C8.73711 4.92669 8.26312 4.92669 7.96988 5.21993L4.21993 8.96985C3.92669 9.2631 3.92669 9.73709 4.21993 10.0303L7.96988 13.7803C8.11612 13.9265 8.30812 14 8.50012 14C8.69211 14 8.88411 13.9265 9.03036 13.7803C9.3236 13.487 9.3236 13.013 9.03036 12.7198L6.56065 10.2501H15.25C15.6648 10.2501 16 9.91408 16 9.50009C16 9.0861 15.6648 8.75011 15.25 8.75011Z'
          fill={color}
        />
      </g>
    </svg>
  );
};

export default BackArrow;
