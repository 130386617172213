import React from 'react';
import { useRouter } from 'next/router';
import { useGlobalState } from 'src/hooks/use-global-state';
import { Navlink, NavButton } from 'src/components/Navlink';
import HomeIcon from 'barn/icons/Home';
import CampaignsIcon from 'barn/icons/Campaigns';
import AutomationIcon from 'barn/icons/Automation';
import SubscribersIcon from 'barn/icons/Subscribers';
import OptinsIcon from 'barn/icons/Optins';
import SegmentsIcon from 'barn/icons/Segments';
import IntegrationsIcon from 'barn/icons/Integrations';
import SettingsIcon from 'barn/icons/Settings';
import ActivityFeedIcon from 'barn/icons/ActivityFeed';
import RocketIcon from 'barn/icons/Rocket';
import EmailIcon from 'barn/icons/Email';
import NewCampaign from 'src/components/NewCampaign';
import useAuth from 'src/hooks/use-auth';
import useTranslation from 'next-translate/useTranslation';
import * as Styled from './styled';
import { primaryRoutes, secondaryRoutes } from './routes';
import { FeedbackWidget } from './FeedbackWidgetOld';
import Sidebar from './SidebarOld';

const icons = {
  'page.home': HomeIcon,
  'page.campaigns': CampaignsIcon,
  'page.automation': AutomationIcon,
  'page.subscribers': SubscribersIcon,
  'page.segments': SegmentsIcon,
  'page.optins': OptinsIcon,
  'page.integrations': IntegrationsIcon,
  'page.settings': SettingsIcon,
  'page.activityFeed': ActivityFeedIcon,
  'page.gettingStarted': RocketIcon,
  'page.email': EmailIcon,
};

const LeftNavigationOld: React.FunctionComponent = () => {
  const { t } = useTranslation('common');
  const {
    state: { isSidebarOpen, isSmallScreen },
  } = useGlobalState('common');
  const { route } = useRouter();
  const { user } = useAuth();
  const isPlatformBigCommerce = user?.website?.platform === 'bigcommerce';

  const isRouteActive = (currentPath, path) => {
    return path === '/'
      ? currentPath === path
      : currentPath.replace(/^\//, '').startsWith(path.replace(/^\//, ''));
  };

  // Spread the array so that we don't accidentally modify the actual exported array
  let filteredPrimaryRoutes = [...primaryRoutes];
  if (isPlatformBigCommerce) {
    filteredPrimaryRoutes = filteredPrimaryRoutes.filter(
      item => !/integrations/.test(item.path),
    );
  }

  if (!user || !Object.keys(user).length) {
    return null;
  }

  const isEmailEnabled = user?.website?.flags?.brevo_email === 'enabled';
  filteredPrimaryRoutes = filteredPrimaryRoutes.filter(
    item => item.key !== 'page.email' || isEmailEnabled,
  );

  const cta =
    isSmallScreen && !isSidebarOpen ? (
      <NewCampaign iconOnly />
    ) : (
      <NewCampaign />
    );

  return (
    <Sidebar cta={cta}>
      <Styled.Wrapper>
        <Styled.PrimaryRoutesWrapper>
          {filteredPrimaryRoutes.map(
            ({ key, path, name, isActive, subRoutes, showNewBadge }) => {
              const Icon = icons[key];
              const { platform } = user?.website || {};

              // Only the main automations link should be present if the platform isn't shopify
              if (subRoutes && platform && platform !== 'shopify')
                /* eslint-disable no-param-reassign */
                subRoutes = subRoutes.filter(
                  sub => sub.key === 'page.automation',
                );

              return (
                isSidebarOpen && (
                  <React.Fragment key={key}>
                    <Navlink
                      href={path}
                      label={t(name)}
                      icon={<Icon />}
                      hideLabels={isSmallScreen && !isSidebarOpen}
                      active={
                        isActive ? isActive(route) : isRouteActive(route, path)
                      }
                      locked={
                        key === 'page.segments'
                          ? user &&
                            user.website.website_plan &&
                            !user.website.website_plan.enable_segmentation
                          : false
                      }
                      fillIcon={subRoutes
                        ?.map(({ isActive }) => isActive(route))
                        .includes(true)}
                      showNewBadge={showNewBadge}
                    />
                    {subRoutes &&
                      /*
                       * Don't show subroutes on smaller screen as
                       * the nav would be too small. They would be accessible
                       * when the user opens up the sidebar
                       */
                      (!isSmallScreen || isSidebarOpen) &&
                      subRoutes.map(
                        ({
                          key: subRouteKey,
                          name: subRouteName,
                          path: subRoutePath,
                          isActive: isSubPathActive,
                        }) => (
                          <Navlink
                            key={subRouteKey}
                            href={subRoutePath}
                            label={t(subRouteName)}
                            active={isSubPathActive(route)}
                            locked={
                              subRouteKey === 'page.acr'
                                ? user &&
                                  user.website.website_plan &&
                                  !user.website.website_plan
                                    .enable_abandoned_cart
                                : false
                            }
                            sublink
                          />
                        ),
                      )}
                  </React.Fragment>
                )
              );
            },
          )}
        </Styled.PrimaryRoutesWrapper>
        <div>
          <FeedbackWidget />
          <Styled.SecondaryRoutes>
            {secondaryRoutes.map(({ key, path, name, isLocked }) => {
              const Icon = icons[key];
              if (isSidebarOpen) {
                return path ? (
                  <Navlink
                    key={key}
                    href={path}
                    label={t(name)}
                    icon={<Icon />}
                    active={route
                      .replace(/^\//, '')
                      .startsWith(path.replace(/^\//, ''))}
                    hideLabels={isSmallScreen && !isSidebarOpen}
                    locked={isLocked}
                  />
                ) : (
                  <NavButton
                    key={key}
                    label={t(name)}
                    locked={isLocked}
                    icon={<Icon />}
                  />
                );
              }
              return null;
            })}
          </Styled.SecondaryRoutes>
        </div>
      </Styled.Wrapper>
    </Sidebar>
  );
};

export default LeftNavigationOld;
