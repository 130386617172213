/* eslint-disable react/button-has-type */
import { Badge, BlockStack, Box, InlineStack } from '@shopify/polaris';
import tokens from 'barn/tokens';
import { Banner } from 'src/components/Banners';
import { EmailBanner } from 'src/components/EmailBanner';
import styled from 'styled-components';
import BackLink from './BackLink';

const StyledTitleBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: ${tokens.space.padding[5]};
  padding-bottom: 0;
  margin: 0 auto;
  width: 100%;
`;

const StyledTitle = styled.h1`
  font-size: ${tokens.fontSizes[5]};
  font-weight: 400;
  margin: 0;
  color: ${tokens.colors.greys[0]};
  padding: 0;
`;

export const Seperator = styled.div`
  width: 100%;
  border-bottom: 1px solid ${tokens.colors.greys[5]};
`;

const StyledDescription = styled.p`
  display: block;
  margin: ${tokens.space.margin[2]} 0 0 0;
  font-size: ${tokens.fontSizes[3]};
  color: ${tokens.colors.greys[2]};
  padding-left: ${tokens.space.padding[3]};
`;

interface Props {
  title: string;
  description?: string;
  parentPageHref?: string;
  widget?: React.ReactNode;
  isLocked?: boolean;
}
const TitleBar: React.FunctionComponent<Props> = ({
  title,
  description,
  widget = null,
  parentPageHref = null,
  isLocked = false,
}) => {
  return (
    <BlockStack gap='200'>
      <StyledTitleBar>
        <Box width='100%'>
          <BlockStack gap='200'>
            <InlineStack blockAlign='center' align='space-between' gap='200'>
              <StyledTitle>
                <InlineStack blockAlign='center' gap='300'>
                  {parentPageHref && <BackLink href={parentPageHref} />}
                  {title}
                  {isLocked && <Badge tone='attention'>Locked</Badge>}
                </InlineStack>
              </StyledTitle>
              {widget}
            </InlineStack>
            {!description && (
              <StyledDescription>{description}</StyledDescription>
            )}
          </BlockStack>
        </Box>
      </StyledTitleBar>
      <Seperator />
      <EmailBanner />
      <Banner />
    </BlockStack>
  );
};

export default TitleBar;
