import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { useGlobalState } from 'src/hooks/use-global-state';
import { isSessionStorageAvailable } from 'src/lib/utils';
import { useBrevoEmailEnabled } from 'src/hooks/use-brevo-email-enabled';
import { FancyBanner } from './FancyBanner';

const EMAIL_BANNER_DISMISS_KEY = 'email-banner-dismissed';

const getDismissedBanners = () => {
  if (isSessionStorageAvailable()) {
    try {
      const dismissedBanner = sessionStorage.getItem(EMAIL_BANNER_DISMISS_KEY);
      return dismissedBanner;
    } catch (error) {
      return false;
    }
  }

  return false;
};

const setDismissedBanners = () => {
  if (isSessionStorageAvailable()) {
    try {
      sessionStorage.setItem(EMAIL_BANNER_DISMISS_KEY, 'true');
      // eslint-disable-next-line no-empty
    } catch (error) {}
  }
};

export const EmailBanner = () => {
  const { t } = useTranslation('common');
  const isEmailEnabled = useBrevoEmailEnabled();

  const router = useRouter();
  const [isBannerDismissed, setIsBannerDismissed] = useState(
    getDismissedBanners(),
  );
  const {
    state: { isAuthenticating, user },
  } = useGlobalState('user');

  const handleBannerDismiss = () => {
    setIsBannerDismissed(true);
    setDismissedBanners();
  };

  if (user.website.platform !== 'shopify') return null;

  if (router.pathname !== '/campaigns' && router.pathname !== '/automation') {
    return null;
  }

  if (isBannerDismissed || isAuthenticating) {
    return null;
  }

  if (isEmailEnabled) return null;

  return (
    <FancyBanner
      title={t('Introducing PushOwl Email')}
      description={t('Email banner description')}
      ctaText={t('Get 500 free emails')}
      ctaLink={`https://pushowl.typeform.com/to/Z6lyu8dQ#subdomain=${user.website.subdomain}`}
      tracking='email-waitlist'
      onClose={handleBannerDismiss}
      rightImage='/static/img/EmailBanner.png'
      hideAnimations
    />
  );
};
