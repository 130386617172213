import LeftNavigation from 'src/components/LeftNavigation';
import Navbar from 'src/components/Navbar';

export const Navigation = () => {
  return (
    <>
      <LeftNavigation />
      <Navbar />
    </>
  );
};
