import useTranslation from 'next-translate/useTranslation';
import { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { sidebarWidth, responsiveSidebarWidth } from 'barn/tokens/layout';
import ProfileMenu from 'src/components/ProfileMenu';
import useAuth from 'src/hooks/use-auth';
import tokens from 'barn/tokens';
import { useGlobalState } from 'src/hooks/use-global-state';
import { Button } from '@shopify/polaris';
import { useBrevoEmailEnabled } from 'src/hooks/use-brevo-email-enabled';
import { MenuIcon } from '@shopify/polaris-icons';

const StyledTopbar = styled.div<{ isSidebarOpen: boolean }>`
  z-index: ${tokens.zIndex[2]};
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  background: ${tokens.colors.greys[8]};
  border-bottom: 1px solid ${tokens.colors.greys[5]};
  padding: 0 ${tokens.space.padding[7]};
  padding-left: 0;
  height: 52px;

  justify-content: space-between;
  padding-left: ${props => (props.isSidebarOpen ? sidebarWidth : 0)};

  @media screen and (max-width: ${tokens.responsiveScreenBreakpoint}) {
    padding-left: ${responsiveSidebarWidth};
  }

  z-index: var(--p-z-index-2);
`;

const Navbar = () => {
  const { t } = useTranslation('common');

  const { user } = useAuth();
  const {
    actions: { showNewPricing },
  } = useGlobalState('pricing');
  const { actions: pricingV2Actions } = useGlobalState('pricingV2');
  const {
    actions: { toggleSidebar },
    state: { isSidebarOpen, isSmallScreen },
  } = useGlobalState('common');

  const isNewPricingEnabled = useBrevoEmailEnabled();

  const isPlatformBigCommerce = user?.website?.platform === 'bigcommerce';

  const buttonRef = useRef(null);

  useEffect(() => {
    if (!buttonRef.current) return;
    const rect = buttonRef.current.getBoundingClientRect();
    document.documentElement.style.setProperty(
      '--changelog-top',
      `${rect.bottom + 10}px`,
    );
  }, [user]);

  useEffect(() => {
    if (!isSidebarOpen && !isSmallScreen) {
      toggleSidebar();
    }
  }, [isSmallScreen, isSidebarOpen]);

  if (!user || !Object.keys(user).length) {
    return null;
  }

  return (
    <StyledTopbar data-test='main-header' isSidebarOpen={isSidebarOpen}>
      <div
        style={{
          position: 'relative',
        }}
      >
        {isSmallScreen && (
          <Button
            aria-label='toggle sidebar'
            onClick={toggleSidebar}
            icon={MenuIcon}
          />
        )}
      </div>
      <span style={{ display: 'flex' }}>
        <button
          aria-label='Changelog'
          type='button'
          id='js-changelog-wrap'
          style={{
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            border: 0,
            background: 'none',
            marginRight: 20,
            padding: 0,
            cursor: 'pointer',
          }}
          data-canny-changelog
          ref={buttonRef}
        >
          <svg style={{ width: 24, height: 24 }} viewBox='0 0 16 20'>
            <path
              d='M8 20a2 2 0 002-2H6a2 2 0 002 2zM0 10a8 8 0 1116 0v3a4 4 0 01-4 4H4a4 4 0 01-4-4v-3z'
              fill='#9FB2C5'
            />
            <rect x='7' width='2' height='3' rx='1' fill='#9FB2C5' />
          </svg>
        </button>

        {user.isBasic && !isPlatformBigCommerce && (
          <Button
            id='upgrade-header'
            onClick={() =>
              isNewPricingEnabled
                ? pricingV2Actions.goToPricing({
                    utmMedium: 'top_nav',
                  })
                : showNewPricing({ utmMedium: 'top_nav' })
            }
            variant='primary'
            tone='success'
          >
            {t('Upgrade')}
          </Button>
        )}
        <ProfileMenu />
      </span>
    </StyledTopbar>
  );
};

export default Navbar;
