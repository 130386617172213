const FeedbackOwlyBanner = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={32}
      height={57}
      viewBox='0 0 32 57'
      fill='none'
    >
      <mask
        id='mask0_3627_15876'
        style={{
          maskType: 'alpha',
        }}
        maskUnits='userSpaceOnUse'
        x={0}
        y={0}
        width={32}
        height={57}
      >
        <rect width={32} height={57} fill='#C4C4C4' />
      </mask>
      <g mask='url(#mask0_3627_15876)'>
        <path
          d='M3.64315 60.9003C6.04315 74.3003 9.94315 87.8003 19.7432 97.3003C33.1432 110.3 59.3431 114.8 77.2431 109.3V71.9003C43.7431 75.2003 29.0432 63.7003 26.4432 54.9003C25.7284 52.4879 25.4432 52.5003 26 47.0003C26.5568 41.5003 29 40.5003 31 34.5003C33 28.5003 28 26.0003 26.4432 29.0003C24.8863 32.0003 24.8206 33.3459 24.8447 32.5003C24.8906 30.888 24.9432 29.9003 24.9432 29.9003C25.2432 26.8003 25.4432 23.7003 25.4432 20.6003C25.4432 19.5003 25.3432 18.2003 24.3432 17.6003C23.5432 17.1003 21.9432 17.2003 21.2432 17.9003C20.5432 18.6003 20.2432 19.5003 19.9432 20.4003C19.4432 23.0003 19.0432 25.9003 18.8432 28.6003C18.8432 26.0003 19.9 12.8003 19.5 12.0003C18.9 10.6003 16.3 10.1003 15.3 12.4003C15 13.0003 13.6432 24.4003 13.5432 25.3003C13.5432 24.4003 13.6 14.8003 13 14.0003C12.4 13.3003 10.7 13.4003 10 13.8003C9.4 14.1003 8.54315 24.3003 8.44315 24.9003C8.34315 25.6003 8.24315 26.2003 8.14315 26.7003L8.04315 22.2003C8.04315 19.2003 8.44315 16.0003 5.44315 16.0003C3.74315 16.1003 3.64315 18.6003 3.44315 20.2003C1.84315 33.7003 1.14315 47.5003 3.64315 60.9003ZM13.3432 28.3003C13.3432 28.8003 13.3432 29.2003 13.3432 29.6003C13.2432 29.1003 13.2432 28.7003 13.3432 28.3003Z'
          fill='#ECC94B'
        />
        <path
          d='M3.64315 60.9003C6.04315 74.3003 9.94315 87.8003 19.7432 97.3003C33.1432 110.3 59.3431 114.8 77.2431 109.3V71.9003C43.7431 75.2003 29.0432 63.7003 26.4432 54.9003C25.7284 52.4879 25.4432 52.5003 26 47.0003C26.5568 41.5003 29 40.5003 31 34.5003C33 28.5003 28 26.0003 26.4432 29.0003C24.8863 32.0003 24.8206 33.3459 24.8447 32.5003C24.8906 30.888 24.9432 29.9003 24.9432 29.9003C25.2432 26.8003 25.4432 23.7003 25.4432 20.6003C25.4432 19.5003 25.3432 18.2003 24.3432 17.6003C23.5432 17.1003 21.9432 17.2003 21.2432 17.9003C20.5432 18.6003 20.2432 19.5003 19.9432 20.4003C19.4432 23.0003 19.0432 25.9003 18.8432 28.6003C18.8432 26.0003 19.9 12.8003 19.5 12.0003C18.9 10.6003 16.3 10.1003 15.3 12.4003C15 13.0003 13.6432 24.4003 13.5432 25.3003C13.5432 24.4003 13.6 14.8003 13 14.0003C12.4 13.3003 10.7 13.4003 10 13.8003C9.4 14.1003 8.54315 24.3003 8.44315 24.9003C8.34315 25.6003 8.24315 26.2003 8.14315 26.7003L8.04315 22.2003C8.04315 19.2003 8.44315 16.0003 5.44315 16.0003C3.74315 16.1003 3.64315 18.6003 3.44315 20.2003C1.84315 33.7003 1.14315 47.5003 3.64315 60.9003ZM13.3432 28.3003C13.3432 28.8003 13.3432 29.2003 13.3432 29.6003C13.2432 29.1003 13.2432 28.7003 13.3432 28.3003Z'
          fill='white'
          fillOpacity={0.25}
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M24.9598 33.2237C25.0833 32.9767 24.9831 32.6764 24.7361 32.5529C24.4892 32.4294 24.1888 32.5295 24.0653 32.7765C23.5516 33.8039 23.2137 34.4669 22.784 34.8966C22.399 35.2816 21.9133 35.5001 21.0125 35.5001C17.4611 35.5001 15.2617 36.9037 13.9909 38.7127C12.74 40.4933 12.4249 42.6132 12.5135 44.0313C12.5307 44.3069 12.7681 44.5164 13.0437 44.4991C13.3193 44.4819 13.5288 44.2445 13.5116 43.9689C13.4335 42.7203 13.7184 40.8403 14.8092 39.2875C15.88 37.7632 17.764 36.5001 21.0125 36.5001C22.1118 36.5001 22.8761 36.2187 23.4911 35.6037C24.049 35.0458 24.4554 34.2326 24.9281 33.287L24.9598 33.2237ZM6.9068 63.1768C8.2068 73.4768 12.6068 83.2768 19.4068 91.1768C26.2068 98.9768 35.3068 104.677 45.3068 107.377C51.3068 108.977 57.9068 109.777 64.0068 108.177C64.7068 107.977 64.4068 106.977 63.7068 107.077C58.3068 108.577 52.6068 107.977 47.2068 106.677C42.3068 105.477 37.7068 103.677 33.3068 101.177C24.6068 96.1768 17.5068 88.6768 13.1068 79.6768C10.5068 74.4768 8.8068 68.9768 8.1068 63.1768C7.9068 62.4768 6.8068 62.4768 6.9068 63.1768ZM67.5068 105.977V105.777V105.577C67.5068 105.527 67.4818 105.502 67.4568 105.477C67.4318 105.452 67.4068 105.427 67.4068 105.377C67.4068 105.277 67.3068 105.277 67.2068 105.277H67.0068H66.9068C66.8068 105.277 66.7068 105.377 66.7068 105.377L66.6068 105.477C66.6068 105.527 66.5818 105.577 66.5568 105.627C66.5318 105.677 66.5068 105.727 66.5068 105.777V105.977V106.177C66.5068 106.227 66.5318 106.252 66.5568 106.277C66.5818 106.302 66.6068 106.327 66.6068 106.377C66.6068 106.477 66.7068 106.477 66.8068 106.477H67.0068H67.1068C67.2068 106.477 67.3068 106.377 67.3068 106.377L67.4068 106.277C67.4068 106.227 67.4318 106.177 67.4568 106.127C67.4818 106.077 67.5068 106.027 67.5068 105.977Z'
          fill='#212B36'
        />
      </g>
    </svg>
  );
};

export default FeedbackOwlyBanner;
