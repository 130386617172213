import { useRouter } from 'next/router';
import useAuth from 'src/hooks/use-auth';
import useCreateCampaign from 'src/hooks/use-create-campaign';
import useCreateNotification from 'src/hooks/use-create-notification';

const useNewCampaign = () => {
  const router = useRouter();
  const { user } = useAuth();
  const { actions: createCampaignActions } = useCreateCampaign();
  const { actions: createNotificationActions } = useCreateNotification();

  // click and other action handlers
  const startNewCampaign = (campaignProps = {}) => {
    createCampaignActions.start({ ...campaignProps, isDraft: true });
    createNotificationActions.shut(); // shut if not already
    createNotificationActions.loadState({
      icon: user && user.website && user.website.company_logo,
    });
    router.push('/campaigns/create/1');
  };

  return { startNewCampaign };
};

export { useNewCampaign };
