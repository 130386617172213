import { v1 as uuidv1 } from 'uuid';
import colors from 'barn/tokens/colors';

const Integrations = ({ color = colors.greys[2], style = {} }) => {
  const id = uuidv1();

  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' style={style}>
      <mask
        id={`maskIntegrations${id}`}
        mask-type='alpha'
        maskUnits='userSpaceOnUse'
        x='2'
        y='2'
        width='16'
        height='16'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M15.6 16.4C15.1592 16.4 14.8 16.0416 14.8 15.6C14.8 15.1584 15.1592 14.8 15.6 14.8C16.0408 14.8 16.4 15.1584 16.4 15.6C16.4 16.0416 16.0408 16.4 15.6 16.4ZM7.6 14.8C6.2768 14.8 5.2 13.7232 5.2 12.4C5.2 11.0768 6.2768 10 7.6 10C8.9232 10 10 11.0768 10 12.4C10 13.7232 8.9232 14.8 7.6 14.8ZM3.6 4.4C3.6 3.9584 3.9592 3.6 4.4 3.6C4.8408 3.6 5.2 3.9584 5.2 4.4C5.2 4.8416 4.8408 5.2 4.4 5.2C3.9592 5.2 3.6 4.8416 3.6 4.4ZM15.6 3.6C16.0408 3.6 16.4 3.9584 16.4 4.4C16.4 4.8416 16.0408 5.2 15.6 5.2C15.1592 5.2 14.8 4.8416 14.8 4.4C14.8 3.9584 15.1592 3.6 15.6 3.6ZM15.6 13.2C14.9544 13.2 14.3704 13.4592 13.9384 13.8752L11.5704 12.6904C11.5776 12.5928 11.6 12.5 11.6 12.4C11.6 11.5856 11.3528 10.8296 10.9336 10.1976L14.5656 6.5656C14.5672 6.5632 14.568 6.5608 14.5704 6.5584C14.8832 6.7088 15.2304 6.8 15.6 6.8C16.9232 6.8 18 5.7232 18 4.4C18 3.0768 16.9232 2 15.6 2C14.2768 2 13.2 3.0768 13.2 4.4C13.2 4.7704 13.2912 5.1168 13.4416 5.4296C13.4392 5.432 13.436 5.432 13.4344 5.4344L9.8024 9.0664C9.1696 8.6472 8.4144 8.4 7.6 8.4C7.5 8.4 7.4072 8.4216 7.3096 8.4296L6.1248 6.0608C6.5408 5.6296 6.8 5.0456 6.8 4.4C6.8 3.0768 5.7232 2 4.4 2C3.0768 2 2 3.0768 2 4.4C2 5.7232 3.0768 6.8 4.4 6.8C4.5 6.8 4.5944 6.7832 4.6904 6.7704L5.7448 8.8784C4.476 9.5496 3.6 10.868 3.6 12.4C3.6 14.6056 5.3944 16.4 7.6 16.4C9.1328 16.4 10.4504 15.524 11.1216 14.2552L13.2296 15.3096C13.2176 15.4056 13.2 15.5 13.2 15.6C13.2 16.9232 14.2768 18 15.6 18C16.9232 18 18 16.9232 18 15.6C18 14.2768 16.9232 13.2 15.6 13.2Z'
          fill='#31373D'
        />
      </mask>
      <g mask={`url(#maskIntegrations${id})`}>
        <rect
          className='iconColor'
          width='16'
          height='16'
          transform='translate(2 2)'
          fill={color}
        />
      </g>
    </svg>
  );
};

export default Integrations;
