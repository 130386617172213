import layout, { sidebarSpacing } from 'barn/tokens/layout';
import styled from 'styled-components';
import tokens from 'barn/tokens';

const { top, bottom, width, responsiveWidth } = layout.sidebar;

const spaceBetween = 'space-between';

const StyledSidebar = styled.div<{ isSidebarOpen: boolean }>`
  z-index: var(--p-z-index-3);
  display: ${props => (props.isSidebarOpen ? 'flex' : 'none')};
  flex-direction: column;
  position: fixed;
  top: ${top};
  bottom: ${bottom};
  width: ${width};
  background: ${tokens.colors.greys[8]};
  border-right: 1px solid ${tokens.colors.greys[5]};
  min-height: 584px;

  @media screen and (max-width: ${tokens.responsiveScreenBreakpoint}) {
    width: ${props => (props.isSidebarOpen ? width : responsiveWidth)};
  }
`;

export const StyledLogoHolder = styled.div<{ isSmallScreen: boolean }>`
  display: flex;
  justify-content: ${props => (props.isSmallScreen ? spaceBetween : 'center')};
  align-items: center;
  width: 100%;
  height: 60px;
  gap: ${tokens.space.margin[2]};
  padding: 0 ${sidebarSpacing};
`;

export const StyledSidebarItem = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  margin: 4px ${sidebarSpacing};
  border-radius: ${sidebarSpacing};
`;

export const StyledSidebarChildItem = styled.div`
  display: flex;
  column-gap: ${sidebarSpacing};
  align-items: center;
  border-radius: ${sidebarSpacing};
  padding: ${sidebarSpacing};
`;

export const FeedbackOwlyContainer = styled.div`
  margin-bottom: 24px;
  margin-top: auto;
  display: flex;
  align-items: center;
  column-gap: ${sidebarSpacing};
  border-bottom: 1px solid var(--p-color-bg-subdued-active);
  margin-left: ${sidebarSpacing};
  justify-content: space-around;

  > :nth-child(2) {
    width: 80%;
  }
`;

export const CloseButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
`;

export default StyledSidebar;
