import { Badge, Box, Icon, Text } from '@shopify/polaris';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  ListBulletedIcon,
} from '@shopify/polaris-icons';
import { sidebarSpacing } from 'barn/tokens/layout';
import useTranslation from 'next-translate/useTranslation';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { FeedbackWidget } from 'src/components/FeedbackWidget';
import FeedbackOwlyBanner from 'src/components/LeftNavigation/FeedbackOwlyBanner';
import LeftNavigationOld from 'src/components/LeftNavigationOld';
import Sidebar from 'src/components/Sidebar';
import useAuth from 'src/hooks/use-auth';
import { useEmailActivated } from 'src/hooks/use-email-activated';
import {
  CloseButton,
  FeedbackOwlyContainer,
  StyledSidebarChildItem,
  StyledSidebarItem,
} from '../Sidebar/StyledSidebar';
import { emailRoutes, routes, type Route } from './routes';

const isPathActive = (targetPath: string, pathname: string) => {
  if (pathname.includes('ecommerce')) {
    return false;
  }
  if (targetPath === '/' && pathname === '/') {
    return true;
  }
  if (targetPath) {
    return pathname
      .split('/')
      .filter(Boolean)
      .includes(targetPath.split('/')[1]);
  }
  return false;
};

const calculateStyles = (
  item: Route,
  expandedList: string[],
  isLastItem: boolean,
  pathname: string,
) => {
  const { key } = item;
  const styleObj: React.CSSProperties = {};
  if (
    key.includes('subroute') &&
    (key.includes('email') || !key.includes('segments'))
  ) {
    styleObj.paddingLeft = 0;
    styleObj.marginLeft = 0;
    styleObj.marginRight = 0;
  }

  if (expandedList.includes(item.name) && item.subRoutes.length) {
    styleObj.backgroundColor = 'var(--p-color-surface-secondary-temp)';
  }

  if (isLastItem) {
    styleObj.marginBottom = sidebarSpacing;
  } else {
    styleObj.marginBottom = 0;
  }

  if (!item?.parent) {
    styleObj.marginTop = 0;
  }

  if (item.name === 'Email') {
    styleObj.borderTopLeftRadius = 0;
    styleObj.borderTopRightRadius = 0;
  }

  if (
    (item.name === 'Webpush' && expandedList.includes('Email')) ||
    isPathActive('/email', pathname)
  ) {
    styleObj.borderBottomRightRadius = 0;
    styleObj.borderBottomLeftRadius = 0;
  }

  return styleObj;
};

const LeftNavigation: React.FunctionComponent = () => {
  const { t } = useTranslation('left-sidebar');

  const { user } = useAuth();
  const router = useRouter();
  const isEmailActivated = useEmailActivated();

  const isIntegratedDashboard =
    user?.website?.flags?.integrated_dashboard === 'enabled';
  const [expandedList, setExpandedList] = useState<string[]>([
    isIntegratedDashboard ? 'Home' : 'Webpush',
  ]);

  const removeIntegrationSubroute = (routes: Route[]) => {
    return routes
      .map(route => {
        if (route.subRoutes) {
          route.subRoutes = removeIntegrationSubroute(route.subRoutes);
        }
        return route.name !== 'Integrations' ? route : null;
      })
      .filter(Boolean); // Remove null values
  };

  let filteredRoutes = [...routes];

  if (isEmailActivated) {
    filteredRoutes = filteredRoutes.map(route => {
      if (route.key === 'sidebar.email') {
        return { ...route, subRoutes: emailRoutes };
      }

      return route;
    });
  }

  const isPlatformBigCommerce = user?.website?.platform === 'bigcommerce';
  if (isPlatformBigCommerce) {
    filteredRoutes = removeIntegrationSubroute(filteredRoutes);
  }

  const isEmailEnabled = user?.website?.flags?.brevo_email === 'enabled';
  if (!isEmailEnabled) {
    filteredRoutes = filteredRoutes.filter(
      route => route.name !== 'Email' && route.name !== 'Analytics',
    );
  }

  if (!isIntegratedDashboard) {
    filteredRoutes = filteredRoutes
      .filter(item => item.name !== 'Home') // remove home route
      .map(item => {
        if (item.name === 'Webpush') {
          return {
            ...item,
            path: '/', // change webpush path to home path
          };
        }
        return item;
      });
  }

  const parseJSON = (toParse: any) => {
    try {
      return JSON.parse(toParse);
    } catch (err) {
      return undefined;
    }
  };

  useEffect(() => {
    const expandedSidebarList = sessionStorage.getItem('sidebar');
    const { pathname } = router;
    let routesArr = [];
    if (expandedSidebarList) {
      const parsedArr = parseJSON(expandedSidebarList);
      routesArr = parsedArr ?? [];
    } else {
      routesArr = [isIntegratedDashboard ? 'Home' : 'Webpush'];
    }
    if (pathname === '/email' && !routesArr.includes('Email')) {
      routesArr.push('Email');
    }
    setExpandedList(routesArr);
  }, []);

  const handleListExpansion = (itemToExpand: string) => {
    if (expandedList.includes(itemToExpand)) {
      let items = [...expandedList];
      items = items.filter(t => t !== itemToExpand);
      setExpandedList(items);
      sessionStorage.setItem('sidebar', JSON.stringify(items));
    } else {
      const items = [...expandedList];
      items.push(itemToExpand);
      setExpandedList(items);
      sessionStorage.setItem('sidebar', JSON.stringify(items));
    }
  };

  if (!user || !Object.keys(user).length) {
    return null;
  }

  return isEmailEnabled ? (
    <Sidebar>
      <RenderNavItems
        navItem={filteredRoutes}
        expandedList={expandedList}
        pathname={router.pathname}
        handleListExpansion={handleListExpansion}
      />
      <RenderFeedbackSection
        text={t('Hey! want to help us make pushowl better?')}
      />
      <StyledSidebarItem
        style={{
          marginBottom: '32px', // sidebarSpacing * 4
        }}
      >
        <Link href='/pricing'>
          <StyledSidebarChildItem>
            <div>
              <Icon tone='base' source={ListBulletedIcon} />
            </div>
            <RenderNavItemText
              itemName='Pricing'
              isNewBadgeVisible={false}
              isPathActive={isPathActive('/pricing', router.pathname)}
            />
          </StyledSidebarChildItem>
        </Link>
      </StyledSidebarItem>
    </Sidebar>
  ) : (
    <LeftNavigationOld />
  );
};

const RenderNavItems: React.FC<{
  navItem: Route[];
  expandedList: string[];
  pathname: string;
  handleListExpansion: (name: string) => void;
}> = ({ navItem, expandedList, pathname, handleListExpansion }) => {
  const navItemClickHandler = (ev: React.MouseEvent, item: Route) => {
    ev.stopPropagation();
    ev.preventDefault();
    if (item.subRoutes) {
      handleListExpansion(item.name);
    }
  };

  return (
    <>
      {navItem.map((item, idx) => {
        const isLastItem = idx === navItem.length - 1;
        return (
          <StyledSidebarItem
            style={{
              ...calculateStyles(item, expandedList, isLastItem, pathname),
            }}
            onClick={ev => navItemClickHandler(ev, item)}
            key={item.key}
          >
            <Link href={item.path}>
              <StyledSidebarChildItem
                style={
                  isPathActive(item.path, pathname)
                    ? { backgroundColor: 'var(--p-color-surface-success-temp)' }
                    : null
                }
              >
                <Box paddingInlineStart={item.icon ? '0' : '400'}>
                  {item.icon ? <Icon source={item.icon} tone='base' /> : null}
                </Box>
                <div style={{ flexGrow: 1 }}>
                  <RenderNavItemText
                    itemName={item.name}
                    isNewBadgeVisible={item.isNewBadgeVisible}
                    isPathActive={isPathActive(item.path, pathname)}
                  />
                </div>
                {item.subRoutes.length ? (
                  <div>
                    <Icon
                      tone='base'
                      source={
                        expandedList.includes(item.name)
                          ? ChevronUpIcon
                          : ChevronDownIcon
                      }
                    />
                  </div>
                ) : null}
              </StyledSidebarChildItem>
            </Link>
            {item.subRoutes && expandedList.includes(item.name) ? (
              <RenderNavItems
                navItem={item.subRoutes}
                expandedList={expandedList}
                pathname={pathname}
                handleListExpansion={handleListExpansion}
              />
            ) : null}
          </StyledSidebarItem>
        );
      })}
    </>
  );
};

const RenderFeedbackSection: React.FC<{ text: string }> = ({ text }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const closeModal = () => setIsModalOpen(false);

  return (
    <>
      <FeedbackWidget isOpen={isModalOpen} closeModal={closeModal} />
      <FeedbackOwlyContainer>
        <FeedbackOwlyBanner />
        <CloseButton
          type='button'
          onClick={() => setIsModalOpen(true)}
          style={{ textAlign: 'left' }}
        >
          <Text as='p' tone='subdued'>
            {text}
          </Text>
        </CloseButton>
      </FeedbackOwlyContainer>
    </>
  );
};

const RenderNavItemText: React.FC<{
  itemName: string;
  isNewBadgeVisible: boolean;
  isPathActive: boolean;
}> = ({ itemName, isNewBadgeVisible, isPathActive }) => {
  const { t } = useTranslation('left-sidebar');
  return (
    <div style={{ display: 'flex', columnGap: sidebarSpacing }}>
      <Text
        as='span'
        variant='bodyLg'
        fontWeight={isPathActive ? 'bold' : 'regular'}
        tone={isPathActive ? 'success' : 'subdued'}
      >
        {t(itemName)}
      </Text>
      {isNewBadgeVisible ? (
        <Badge tone='success' size='medium'>
          {t('New')}
        </Badge>
      ) : null}
    </div>
  );
};

export default LeftNavigation;
