import tokens from 'barn/tokens';
import { responsiveSidebarWidth, sidebarWidth } from 'barn/tokens/layout';
import styled from 'styled-components';

const StyledWrapper = styled.div`
  position: relative;
  padding-bottom: ${tokens.space.padding[7]};
  padding-top: ${tokens.space.padding[6]};
  padding-left: calc(${sidebarWidth} + ${tokens.space.padding[7]});

  @media screen and (max-width: ${tokens.responsiveScreenBreakpoint}) {
    padding-right: ${tokens.space.padding[5]};
    max-width: unset !important;
    padding-left: calc(${responsiveSidebarWidth} + ${tokens.space.padding[7]});
  }
`;

const Wrapper = ({ children }) => {
  return <StyledWrapper id='main-content'>{children}</StyledWrapper>;
};

export default Wrapper;
