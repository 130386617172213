import tokens from 'barn/tokens';
import styled from 'styled-components';

const StyledContent = styled.div`
  position: relative;
  border-radius: ${tokens.radii[5]};
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  padding: ${tokens.space.padding[4]} ${tokens.space.padding[5]};
  max-width: unset;
  margin: 0;

  @media screen and (max-width: ${tokens.responsiveScreenBreakpoint}) {
    padding: ${tokens.space.padding[4]} 0;
    max-width: unset !important;
    padding-left: ${tokens.space.padding[4]};
  }
`;

const Content = ({ children }) => {
  return <StyledContent>{children}</StyledContent>;
};

export default Content;
