import { v1 as uuidv1 } from 'uuid';
import colors from 'barn/tokens/colors';

const ActivityFeed = ({ color = colors.greys[2], style = {} }) => {
  const id = uuidv1();

  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' style={style}>
      <mask
        id={`maskIntegrations${id}`}
        mask-type='alpha'
        maskUnits='userSpaceOnUse'
        x='2'
        y='2'
        width='17'
        height='16'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M12.4002 18.0001C12.389 18.0001 12.3786 18.0001 12.3666 17.9993C12.053 17.9865 11.7746 17.7897 11.657 17.4969L8.45697 9.49682C8.33857 9.20002 8.40817 8.86081 8.63457 8.63441C8.86177 8.40801 9.20178 8.33921 9.49698 8.45681L17.497 11.6568C17.7898 11.7744 17.9866 12.0528 17.9994 12.3664C18.013 12.6816 17.8402 12.9752 17.5578 13.116L14.5962 14.596L13.1162 17.5577C12.9802 17.8297 12.7026 18.0001 12.4002 18.0001ZM10.636 10.636L12.4816 15.2488L13.2848 13.6424C13.3616 13.4872 13.4872 13.3616 13.6424 13.2848L15.2488 12.4816L10.636 10.636ZM9.20004 6.00002C8.75763 6.00002 8.40003 5.64242 8.40003 5.20002V2.8C8.40003 2.3576 8.75763 2 9.20004 2C9.64244 2 10 2.3576 10 2.8V5.20002C10 5.64242 9.64244 6.00002 9.20004 6.00002ZM5.20002 10H2.8C2.3576 10 2 9.64244 2 9.20004C2 8.75763 2.3576 8.40003 2.8 8.40003H5.20002C5.64242 8.40003 6.00002 8.75763 6.00002 9.20004C6.00002 9.64244 5.64242 10 5.20002 10ZM6.37204 7.17185C6.16724 7.17185 5.96244 7.09345 5.80644 6.93745L4.10883 5.23984C3.79603 4.92704 3.79603 4.42144 4.10883 4.10863C4.42163 3.79583 4.92723 3.79583 5.24003 4.10863L6.93764 5.80624C7.25044 6.11904 7.25044 6.62465 6.93764 6.93745C6.78164 7.09345 6.57684 7.17185 6.37204 7.17185ZM12.0284 7.17185C11.8236 7.17185 11.6188 7.09345 11.4628 6.93745C11.15 6.62465 11.15 6.11904 11.4628 5.80624L13.1604 4.10863C13.4732 3.79583 13.9788 3.79583 14.2916 4.10863C14.6044 4.42144 14.6044 4.92704 14.2916 5.23984L12.594 6.93745C12.438 7.09345 12.2332 7.17185 12.0284 7.17185ZM4.67443 14.5258C4.46963 14.5258 4.26483 14.4474 4.10883 14.2914C3.79603 13.9786 3.79603 13.473 4.10883 13.1602L5.80644 11.4626C6.11924 11.1498 6.62484 11.1498 6.93764 11.4626C7.25044 11.7754 7.25044 12.281 6.93764 12.5938L5.24003 14.2914C5.08403 14.4474 4.87923 14.5258 4.67443 14.5258Z'
          fill='white'
        />
      </mask>
      <g mask={`url(#maskIntegrations${id})`}>
        <rect width='20' height='20' fill={color} className='iconColor' />
      </g>
    </svg>
  );
};

export default ActivityFeed;
