export default {
  logoWithText: {
    large: { width: '165', height: '48' },
    medium: { width: '110', height: '32' },
    small: { width: '56', height: '16' },
  },

  logo: {
    large: { width: '48', height: '48' },
    medium: { width: '32', height: '32' },
    small: { width: '16', height: '16' },
    mobile: { width: '24', height: '24' },
  },
};
