import useTranslation from 'next-translate/useTranslation';
import { useState } from 'react';
import Textarea from 'barn/components/form/textarea';
import useAuth from 'src/hooks/use-auth';
import { Modal, Text, BlockStack, useBreakpoints } from '@shopify/polaris';
import { submitFeedbackAPI } from 'src/lib/api/feedback';
import { FeedbackSuccess } from './FeedbackSuccess';

export const FeedbackWidget: React.FC<{
  isOpen: boolean;
  closeModal: () => void;
}> = ({ isOpen, closeModal }) => {
  const { t } = useTranslation('common');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [feedback, setFeedback] = useState('');
  const [isSubmissionSuccessful, setIsSubmissionSuccessful] = useState(false);
  const { mdDown } = useBreakpoints();
  const {
    user: {
      website: {
        subdomain,
        shopify_plan: shopifyPlan,
        website_plan: { sku: pushOwlPlan },
      },
    },
  } = useAuth();

  const submitFeedback = async () => {
    setIsSubmitting(true);
    const { error } = await submitFeedbackAPI({
      feedback,
      subdomain,
      shopifyPlan,
      pushOwlPlan,
    });
    setIsSubmitting(false);
    if (!error) {
      setIsSubmissionSuccessful(true);
    }
  };
  const closeWidget = () => {
    closeModal();
    setIsSubmissionSuccessful(false);
    setFeedback('');
  };

  if (mdDown) return null;

  return (
    <Modal
      open={isOpen}
      onClose={closeWidget}
      title={t('Feedback')}
      size='small'
      primaryAction={
        isSubmissionSuccessful
          ? null
          : {
              content: t('Submit Feedback'),
              onAction: submitFeedback,
              loading: isSubmitting,
              disabled: !feedback,
            }
      }
      secondaryActions={[
        {
          content: t('Close'),
          onAction: closeWidget,
        },
      ]}
    >
      <Modal.Section>
        {isSubmissionSuccessful ? (
          <BlockStack gap='300' inlineAlign='center'>
            <FeedbackSuccess />
            <div style={{ textAlign: 'center' }}>
              <Text as='p' variant='bodyLg'>
                {t('Thank you!')}
                <br />
                {t('Your feedback has been submitted.')}
              </Text>
            </div>
          </BlockStack>
        ) : (
          <Textarea
            name='feedback'
            value={feedback}
            onChange={text => {
              setFeedback(text);
            }}
            fullWidth
          />
        )}
      </Modal.Section>
    </Modal>
  );
};
