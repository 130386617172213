import styled from 'styled-components';
import tokens from 'barn/tokens';
import { fontSizes } from 'barn/tokens/typography';

const ProfileMenu = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 0;
  border-left: 1px solid ${tokens.colors.greys[4]};
  padding: 0 ${tokens.space.padding[5]};
  font-size: ${fontSizes[2]};
  cursor: pointer;

  .username {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: ${tokens.space.margin[4]};
    color: ${tokens.colors.greys[1]};
  }
`;

const Popover = styled.div`
  width: 244px;
  background: ${tokens.colors.greys[8]};
  border-radius: ${tokens.radii[3]};
`;

const Meta = styled.div`
  padding: ${tokens.space.padding[5]} ${tokens.space.padding[5]}
    ${tokens.space.padding[4]};

  .websitename {
    font-size: ${fontSizes[1]};
    font-weight: 600;
    text-transform: uppercase;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: default;
  }
`;

const LinkList = styled.ul`
  list-style-type: none;
  display: flex;
  flex-direction: column;
  padding: 0 0 ${tokens.space.margin[2]};
`;

const LinkListItem = styled.button`
  display: flex;
  align-items: center;
  border: none;
  border-top: 1px solid ${tokens.colors.greys[5]};
  padding: ${tokens.space.padding[4]} ${tokens.space.padding[5]};
  font-size: ${fontSizes[2]};
  cursor: pointer;
  color: inherit;

  svg + * {
    margin-left: ${tokens.space.margin[3]};
  }

  &:hover {
    background-color: ${tokens.colors.greys[6]};
  }
`;

const WebsiteItemButton = styled.button`
  cursor: pointer;
  display: block;
  padding: ${tokens.space.padding[3]};
  width: 100%;
  background: none;
  border: none;
  text-align: left;
  border-bottom: 1px solid ${tokens.colors.greys[5]};

  &:hover,
  &[aria-selected='true'] {
    background-color: ${tokens.colors.greys[6]};
  }
`;

export default {
  ProfileMenu,
  Popover,
  Meta,
  LinkList,
  LinkListItem,
  WebsiteItemButton,
};
