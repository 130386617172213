import colors from 'barn/tokens/colors';
import icons from '../variants/icons';

const Logo = ({ color = colors.greys[2], size = 'medium', style = {} }) => {
  const { width, height } = icons.logo[size];

  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 48 48'
      fill='none'
      style={style}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M22.8407 27.1467C22.8407 27.1467 22.8407 27.1495 22.8379 27.1495C24.4042 30.0547 26.9221 32.3369 29.9677 33.614C28.4211 35.9074 26.386 37.9958 23.9747 39.8091C20.974 37.5579 18.5628 34.8744 16.9291 31.9074C16.9432 31.9018 16.96 31.8933 16.974 31.8877C15.5312 32.4519 13.993 32.7383 12.4435 32.7383C5.58036 32.7383 0.0168414 27.2 0.0168414 20.3705C0.0140344 18.7397 0.336846 17.1256 0.968425 15.6211C1.34176 14.6863 1.53262 13.6898 1.53543 12.6821C1.54104 10.9979 1.00211 9.35579 0 8H12.4463C16.6344 7.9972 20.5418 10.0968 22.8379 13.5888C22.1305 14.8997 21.6309 16.3116 21.3642 17.7768C20.0702 13.3558 15.7165 10.5572 11.1495 11.2084C6.58245 11.8597 3.18877 15.7614 3.18877 20.3677C3.18877 24.974 6.58245 28.8758 11.1523 29.527C15.7221 30.1783 20.0758 27.3797 21.367 22.9586C21.6337 24.4239 22.1333 25.8358 22.8407 27.1467ZM12.4463 20.3144C12.0898 20.3144 11.7474 20.4547 11.4947 20.7074C11.2421 20.96 11.1018 21.2997 11.1018 21.6561H9.13403C9.13403 19.8316 10.6162 18.3523 12.4463 18.3523C14.2765 18.3523 15.7586 19.8316 15.7586 21.6561H13.7909C13.7909 20.9151 13.1902 20.3144 12.4463 20.3144ZM35.5565 20.3144C34.8126 20.3144 34.2119 20.9151 34.2119 21.6561H32.2442C32.2442 19.8316 33.7263 18.3523 35.5565 18.3523C37.3867 18.3523 38.8688 19.8316 38.8688 21.6561H36.9011C36.9011 20.9151 36.3004 20.3144 35.5565 20.3144ZM35.5565 11.1158C30.4365 11.127 26.2933 15.273 26.299 20.379C26.3046 25.4877 30.459 29.6225 35.579 29.6197C40.699 29.6169 44.8477 25.4737 44.8477 20.3677C44.8449 15.2533 40.6849 11.1102 35.5565 11.1158ZM47.0513 15.6716C47.0541 15.6772 47.0568 15.6828 47.0597 15.6912C47.6716 17.1733 47.9832 18.7621 47.9832 20.3677C47.9832 27.1972 42.4197 32.7354 35.5593 32.7354C28.6961 32.7354 23.1354 27.1972 23.1354 20.3677C23.1354 13.5383 28.6961 8 35.5565 8H48C46.9979 9.35579 46.459 10.9979 46.4646 12.6821C46.4646 13.7067 46.6639 14.7228 47.0513 15.6716Z'
        fill={color}
      />
    </svg>
  );
};

export default Logo;
