import colors from 'barn/tokens/colors';

const ChevronDown = ({ color = colors.greys[2], style = {} }) => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={style}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.99998 14.67C9.74399 14.67 9.488 14.5487 9.29302 14.3072L4.29323 8.11449C3.90226 7.63021 3.90226 6.84747 4.29323 6.36319C4.68422 5.87894 5.31618 5.87894 5.70717 6.36319L9.99998 11.6803L14.2928 6.36319C14.6837 5.87894 15.3158 5.87894 15.7067 6.36319C16.0978 6.84747 16.0978 7.63021 15.7067 8.11449L10.7069 14.3072C10.5119 14.5487 10.256 14.67 9.99998 14.67Z'
        fill={color}
      />
    </svg>
  );
};

export default ChevronDown;
