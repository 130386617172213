export const primaryRoutes = [
  { key: 'page.home', path: '/', name: 'Home' },
  { key: 'page.campaigns', path: '/campaigns', name: 'Campaigns' },
  {
    key: 'page.automation',
    path: '/automation',
    name: 'Automations',
    isActive: currentPath => {
      return (
        currentPath.match(/\/automation\/.?/) &&
        currentPath.indexOf('automation/abandoned-cart-recovery') === -1
      );
    },
    subRoutes: [
      {
        key: 'page.automation',
        path: '/automation',
        name: 'All Automations',
        isActive: currentPath => {
          return currentPath === '/automation';
        },
      },
      {
        key: 'page.acr',
        path: '/automation/abandoned-cart-recovery',
        name: 'Abandoned Carts',
        isActive: currentPath => {
          return /\/automation\/abandoned-cart-recovery.*/.test(currentPath);
        },
      },
    ],
  },
  { key: 'page.subscribers', path: '/subscribers', name: 'Subscribers' },
  { key: 'page.optins', path: '/optins', name: 'Opt-ins' },
  { key: 'page.integrations', path: '/integrations', name: 'Integrations' },
  { key: 'page.email', path: '/email', name: 'Email', showNewBadge: true },
];

export const secondaryRoutes = [
  /* {
    key: 'page.gettingStarted',
    path: '',
    name: 'Getting Started',
    isLocked: false,
  }, */
  {
    key: 'page.settings',
    path: '/settings',
    name: 'Settings',
    isLocked: false,
  },
  {
    key: 'page.activityFeed',
    path: '/activity',
    name: 'Activity Feed',
    isLocked: false,
  },
];
