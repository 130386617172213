import { v1 as uuidv1 } from 'uuid';
import colors from 'barn/tokens/colors';

const Segments = ({ color = colors.greys[2], style = {} }) => {
  const id = uuidv1();

  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' style={style}>
      <mask
        id={`maskSegments${id}`}
        mask-type='alpha'
        maskUnits='userSpaceOnUse'
        x='2'
        y='2'
        width='16'
        height='17'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M10 2.00024C5.5888 2.00024 2 5.58904 2 10.0002C2 14.4106 5.5888 18.0002 10 18.0002C14.4112 18.0002 18 14.4106 18 10.0002C18 5.58904 14.4112 2.00024 10 2.00024ZM14.4824 14.5602C13.3592 13.873 11.8056 13.2002 10 13.2002C8.1952 13.2002 6.6408 13.8738 5.5168 14.5602C4.3352 13.3986 3.6 11.7842 3.6 10.0002C3.6 6.47144 6.4712 3.60024 10 3.60024C13.5288 3.60024 16.4 6.47144 16.4 10.0002C16.4 11.7842 15.6648 13.3986 14.4824 14.5602ZM6.91953 15.6074C7.77473 15.1658 8.82993 14.8002 10.0003 14.8002C11.1715 14.8002 12.2259 15.1658 13.0811 15.6066C12.1659 16.1106 11.1163 16.4002 10.0003 16.4002C8.88353 16.4002 7.83393 16.1106 6.91953 15.6074ZM10 10C9.1176 10 8.4 9.28245 8.4 8.40005C8.4 7.51765 9.1176 6.80005 10 6.80005C10.8824 6.80005 11.6 7.51765 11.6 8.40005C11.6 9.28245 10.8824 10 10 10ZM10 5.20005C8.2352 5.20005 6.8 6.63525 6.8 8.40005C6.8 10.1648 8.2352 11.6 10 11.6C11.7648 11.6 13.2 10.1648 13.2 8.40005C13.2 6.63525 11.7648 5.20005 10 5.20005Z'
          fill='white'
        />
      </mask>
      <g mask={`url(#maskSegments${id})`}>
        <rect className='iconColor' width='20' height='20' fill={color} />
      </g>
    </svg>
  );
};

export default Segments;
