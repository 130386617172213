export const submitFeedbackAPI = async data => {
  try {
    await fetch(
      `${process.env.NEXT_PUBLIC_FEEDBACK_API_ENDPOINT}/feedback/v1/add`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      },
    );
    return {
      data: null,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error,
    };
  }
};
