export const FeedbackOwly = () => (
  <svg
    width='70'
    height='107'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 70 107'
  >
    <mask
      id='owl2-a'
      maskUnits='userSpaceOnUse'
      x='0'
      y='0'
      width='206'
      height='107'
    >
      <path fill='#C4C4C4' d='M0 0h206v107H0z' />
    </mask>
    <g mask='url(#owl2-a)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.027 49.141c-.76 10.952-1.518 21.904-2.033 29.511h-.016l-.183 2.964c-.145 2.161-.26 3.916-.336 5.148-.038.61-.075 1.232-.098 1.859l-1.202 19.453a26.605 26.605 0 00-.152 2.451l-.003.053h.003c-.002.122-.003.245-.003.367 0 14.535 11.783 26.319 26.319 26.319 14.535 0 26.318-11.784 26.318-26.319 0-.122 0-.245-.002-.367h.865l-2.37-31.928h-.002c-.176-2.609-.376-5.614-.594-8.878v-.014c-.419-6.278-.9-13.509-1.4-20.705L56.57 9.706a9.47 9.47 0 01-.8.868c-4.249 4.07-12.978 5.443-19.23 5.604-6.172-.242-14.896-1.62-19.143-5.69a9.448 9.448 0 01-.802-.868l-2.568 39.521z'
        fill='#FFD650'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.192 79.02a.431.431 0 01.4.462l-2.157 30.627c-.14 3.101.475 8.074 2.784 12.861 2.305 4.78 6.29 9.362 12.894 11.732a.432.432 0 01-.291.813c-6.857-2.462-10.997-7.228-13.38-12.17-2.38-4.933-3.016-10.053-2.869-13.28v-.011l2.158-30.633a.432.432 0 01.46-.4zM60.885 79.02a.431.431 0 00-.4.461l2.157 31.061c.28 5.492-2.462 18.059-15.714 24.175a.431.431 0 10.362.783c13.668-6.308 16.507-19.279 16.214-25.006v-.008h-.001l-2.157-31.065a.431.431 0 00-.46-.4z'
        fill='#000'
      />
      <path
        d='M4.5 102C-5.9 98.4 6.667 70 14 56.5l4 28c7.6 2 28.833-1.333 38.5-3.5 3-.5 10.5-3.5 11 1s-14 1.5-9.5 6.5c3.6 4-.167 7.667-2.5 9 0 0-38 9-51 4.5z'
        fill='#FFD650'
      />
      <path
        d='M4.5 102c13 4.5 51-4.5 51-4.5 2.333-1.333 6.1-5 2.5-9-4.5-5 10-2 9.5-6.5s-8-1.5-11-1c-9.667 2.167-30.9 5.5-38.5 3.5'
        stroke='#000'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <path
      d='M48.79 20.05c.035-.077-.092.053 0 0 .127-.13.276-.158.403-.288a5.365 5.365 0 011.183-.505c1.469-.38 2.793.015 3.885.774.171.075.377.072.54-.136.161-.208.152-.492.016-.645-1.627-1.087-3.776-1.471-5.927-.18-.149.028-.276.158-.46.264-.438.366-.078 1.083.36.717zM61.266 18.792c-1.174-1.243-2.8-1.635-4.126-1.495-.766.056-1.514.375-2.203.676-.18.055-.196.416-.06.642.136.225.34.252.52.197 1.173-.628 2.534-.867 3.947-.32a5.34 5.34 0 011.114.637l.34.252c.111.145 0 0 .026.082.451.396.834-.256.442-.67z'
      fill='#343946'
    />
    <path
      d='M57.028 43.839c2.58 0 4.67-5.07 4.67-11.323s-2.09-11.322-4.67-11.322c-2.579 0-4.67 5.069-4.67 11.322 0 6.253 2.091 11.323 4.67 11.323z'
      fill='#fff'
    />
    <path
      d='M58.838 36.086c5.961 2.72 4.822 11.334 3.507 15.301-3.507-2.38-6.428-6.092-7.451-7.65-1.169-3.684-2.016-10.372 3.944-7.651z'
      fill='#343946'
    />
    <path
      d='M52.358 43.839c2.814 0 5.095-5.07 5.095-11.323s-2.281-11.322-5.095-11.322c-2.813 0-5.094 5.069-5.094 11.322 0 6.253 2.281 11.323 5.094 11.323z'
      fill='#fff'
    />
    <ellipse cx='54.481' cy='32.097' rx='1.274' ry='3.355' fill='#343946' />
    <ellipse cx='59.575' cy='32.097' rx='1.274' ry='3.355' fill='#343946' />
  </svg>
);
