import useTranslation from 'next-translate/useTranslation';
import { useState, memo } from 'react';
import { format } from 'date-fns';
import { ProgressBar } from 'barn/components/ProgressBar';
import DocsIcon from 'barn/icons/Document';
import LogoutIcon from 'barn/icons/Logout';
import PlansIcon from 'barn/icons/Plans';
import ChevronDownIcon from 'barn/icons/ChevronDown';
import SwitchUserIcon from 'barn/icons/SwitchUser';
import Input from 'barn/components/form/input';
import TickCircle from 'barn/components/TickCircle';
import colors from 'barn/tokens/colors';
import useAuth from 'src/hooks/use-auth';
import { useGlobalState } from 'src/hooks/use-global-state';
import { getStorage } from 'src/lib/storage';
import { handleRedirect } from 'src/lib/utils';
import {
  Avatar,
  Button,
  InlineStack,
  Modal,
  Popover,
  Spinner,
  Text,
  BlockStack,
} from '@shopify/polaris';
import { useBrevoEmailEnabled } from 'src/hooks/use-brevo-email-enabled';
import { UTMMedium } from 'src/modules/pricing/models';
import Styled from './styled';
import { ConsumptionsMenuItem } from './ConsumptionsMenuItem';

const ProfileMenu = () => {
  const { t } = useTranslation('common');
  const { user, logout } = useAuth();
  const {
    actions: { showNewPricing },
  } = useGlobalState('pricing');
  const { actions: pricingV2Actions } = useGlobalState('pricingV2');
  const {
    state: { consumption },
  } = useGlobalState('settings');

  const isNewPricingEnabled = useBrevoEmailEnabled();

  const [isPopoverVisible, setPopoverVisibility] = useState(false);

  const subdomain = user && user.website && user.website.subdomain;
  const planName =
    user && user.website.website_plan && user.website.website_plan.name;
  const isPlatformBigCommerce = user.website.platform === 'bigcommerce';

  const websites =
    user.website.flags.enable_multistorelogin === 'disabled'
      ? []
      : [...(user.websites || [])].filter(
          website => website.subdomain !== subdomain,
        );

  const [isWebsiteSwitchModalOpen, setIsWebsiteSwitchModalOpen] =
    useState(false);
  const [selectedSubdomain, setSelectedSubdomain] = useState();
  const [filteredWebsites, setFilteredWebsites] = useState(websites);
  const [websiteSearchTerm, setWebsiteSearchTerm] = useState('');

  const websiteSelectHandler = newSubdomain => {
    setSelectedSubdomain(newSubdomain);
  };
  const onWebsiteSwitchModalCancel = () => {
    setSelectedSubdomain(null);
    setWebsiteSearchTerm('');
    setIsWebsiteSwitchModalOpen(false);
    setFilteredWebsites(websites);
  };
  const switchSubdomain = () => {
    if (!selectedSubdomain) {
      /* eslint-disable no-alert */
      window.alert('Please select a store first.');
      return;
    }
    const answer = window.confirm(
      `Are you sure you want to switch from store "${subdomain}" to store "${selectedSubdomain}"?`,
    );

    if (answer) {
      const storage = getStorage();
      storage.set('segmentdata', null);
      handleRedirect(
        `${process.env.NEXT_PUBLIC_API_ENDPOINT}/auth/v1/pushowl/${
          filteredWebsites.find(site => site.subdomain === selectedSubdomain)
            .platform
        }/?subdomain=${selectedSubdomain}`,
      );
    }
  };

  const websiteSearchInputChangeHandler = (searchText = '') => {
    setWebsiteSearchTerm(searchText);
    if (!searchText) {
      setFilteredWebsites(websites);
      return;
    }

    setFilteredWebsites(
      websites.filter(website => {
        return (
          website.subdomain.indexOf(searchText.toLowerCase()) !== -1 ||
          website.domain.indexOf(searchText.toLowerCase()) !== -1
        );
      }),
    );
  };

  const redirectToPricing = (utmMedium: UTMMedium) => {
    if (isNewPricingEnabled) {
      pricingV2Actions.goToPricing({
        utmMedium,
      });
    } else {
      showNewPricing({ utmMedium });
    }
  };

  return (
    <>
      <Popover
        active={isPopoverVisible}
        preferredPosition='below'
        preferredAlignment='left'
        fullHeight
        onClose={() => setPopoverVisibility(!isPopoverVisible)}
        activator={
          <Styled.ProfileMenu
            data-test='profile-menu'
            onClick={e => {
              setPopoverVisibility(!isPopoverVisible);

              // Stop bubble up to Popover which triggers visibility close
              e.stopPropagation();
            }}
          >
            {user && user.website && user.website.company_logo && (
              <Avatar
                size='sm'
                source={user.website && user.website.company_logo}
                name={user.website.subdomain}
              />
            )}
            <p className='username'>
              {user && user.website && user.website.website_name}&nbsp;
              <ChevronDownIcon />
            </p>
          </Styled.ProfileMenu>
        }
      >
        <Styled.Popover>
          <Styled.Meta>
            <BlockStack gap='200'>
              <span className='websitename' title={subdomain}>
                {subdomain}
              </span>
              <Text variant='bodyMd' as='span'>
                {user.isGILStore
                  ? `${(planName || '').split(' ')[0]} Group`
                  : planName}
              </Text>

              {planName === 'Free' && !isPlatformBigCommerce && (
                <Button
                  tone='success'
                  variant='primary'
                  onClick={() => redirectToPricing('profile_dropdown')}
                  id='profile-menu-upgrade-btn'
                >
                  {t('Upgrade')}
                </Button>
              )}

              {isNewPricingEnabled ? (
                <ConsumptionsMenuItem />
              ) : (
                <>
                  <InlineStack gap='100'>
                    <Text variant='bodyMd' as='span'>
                      {t('Impressions')}{' '}
                      {!consumption.isFetching &&
                        `(${consumption.impressionsConsumed} / ${consumption.impressionsLimit} used)`}
                    </Text>

                    {consumption.isFetching && <Spinner size='small' />}
                  </InlineStack>

                  <ProgressBar
                    width='100%'
                    max={consumption.impressionsLimit}
                    value={consumption.impressionsConsumed}
                  />

                  <InlineStack gap='100'>
                    <Text variant='bodyMd' as='span' tone='subdued'>
                      {t('Impressions reset at')}{' '}
                      {!consumption.isFetching &&
                        format(new Date(consumption.resetDate), 'PPpp')}
                    </Text>

                    {consumption.isFetching && <Spinner size='small' />}
                  </InlineStack>
                </>
              )}
            </BlockStack>
          </Styled.Meta>
          <Styled.LinkList>
            <Styled.LinkListItem
              onClick={() => {
                setPopoverVisibility(false);
                redirectToPricing('profile_dropdown');
              }}
              data-test='profile-menu-plan-pricing-btn'
            >
              <PlansIcon />
              <span>{t('Plans and Pricing')}</span>
            </Styled.LinkListItem>

            {websites && websites.length ? (
              <Styled.LinkListItem
                onClick={() => {
                  setPopoverVisibility(false);
                  setIsWebsiteSwitchModalOpen(true);
                }}
              >
                <SwitchUserIcon />
                <span>{t('Switch Store')}</span>
              </Styled.LinkListItem>
            ) : null}

            <Styled.LinkListItem
              as='a'
              href='//docs.pushowl.com'
              target='_blank'
              rel='noreferrer'
            >
              <DocsIcon />
              <span>{t('Documentation')}</span>
            </Styled.LinkListItem>

            <Styled.LinkListItem onClick={() => logout()} tabIndex={0}>
              <LogoutIcon color={colors.reds[5]} />
              <span>{t('Logout')}</span>
            </Styled.LinkListItem>
          </Styled.LinkList>
        </Styled.Popover>
      </Popover>
      <Modal
        open={isWebsiteSwitchModalOpen}
        onClose={onWebsiteSwitchModalCancel}
        title={t('Select a store to switch to')}
        primaryAction={{
          content: t('Switch Store'),
          onAction: switchSubdomain,
          disabled: !selectedSubdomain,
        }}
        secondaryActions={[
          {
            content: t('Cancel'),
            onAction: onWebsiteSwitchModalCancel,
          },
        ]}
      >
        <Modal.Section>
          <BlockStack gap='600'>
            {websites && websites.length > 5 ? (
              <Input
                name='website-search-term'
                onChange={websiteSearchInputChangeHandler}
                fullWidth
                placeholder='Search stores...'
                value={websiteSearchTerm}
              />
            ) : null}
            <div style={{ maxHeight: '360px', overflowY: 'auto' }}>
              {filteredWebsites &&
                filteredWebsites.map(website => {
                  return (
                    <Styled.WebsiteItemButton
                      key={website.subdomain}
                      aria-selected={selectedSubdomain === website.subdomain}
                      disabled={subdomain === website.subdomain}
                      onClick={() => websiteSelectHandler(website.subdomain)}
                    >
                      <InlineStack gap='300' blockAlign='center'>
                        <div>
                          <TickCircle
                            done={selectedSubdomain === website.subdomain}
                          />
                        </div>
                        <div>
                          <img
                            src={website.company_logo}
                            width='44'
                            height='44'
                            style={{ background: '#D2DCE7', borderRadius: 4 }}
                            alt=''
                          />
                        </div>
                        <BlockStack gap='200'>
                          <Text variant='bodyLg' as='span'>
                            {website.subdomain}
                          </Text>
                          <Text variant='bodyMd' as='span'>
                            {website.domain}
                          </Text>
                        </BlockStack>
                      </InlineStack>
                    </Styled.WebsiteItemButton>
                  );
                })}

              {websites && websites.length && !filteredWebsites.length ? (
                <Text variant='bodyLg' as='span'>
                  {t('No stores found')}
                </Text>
              ) : null}
            </div>
          </BlockStack>
        </Modal.Section>
      </Modal>
    </>
  );
};

export default memo(ProfileMenu);
