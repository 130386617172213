import colors from 'barn/tokens/colors';

const Email = ({ color = colors.greys[2], style = {} }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='20'
      fill='none'
      style={style}
    >
      <g
        style={{
          fill: 'transparent',
        }}
        className='iconColor'
        stroke={color}
        strokeLinejoin='round'
        strokeWidth='2'
      >
        <path d='M17.5 4.5h-15Zm0 0V16h-15V4.5' />
        <path d='M2.5 4.5 10 12l7.5-7.5' />
      </g>
    </svg>
  );
};

export default Email;
