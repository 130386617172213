import Link from 'next/link';
import styled from 'styled-components';
import BackArrowIcon from 'barn/icons/BackArrow';
import tokens from 'barn/tokens';

const StyledLink = styled.a`
  margin-right: ${tokens.space.margin[3]};
  text-decoration: none;
  cursor: pointer;
  display: inline-flex;
  align-items: center;

  svg {
    background-color: ${tokens.colors.greys[8]};
    border-radius: ${tokens.radii[3]};
    border: 1px solid ${tokens.colors.greys[4]};
    height: 28px;
    width: 28px;
  }
`;

const BackLink = ({ href }) => {
  return (
    <Link href={href} passHref legacyBehavior>
      <StyledLink data-test='back-link'>
        <BackArrowIcon color={tokens.colors.greys[2]} />
      </StyledLink>
    </Link>
  );
};

export default BackLink;
