import Link from 'next/link';
import colors from 'barn/tokens/colors';
import LockIcon from 'barn/icons/Lock';
import { useGlobalState } from 'src/hooks/use-global-state';
import { Badge } from '@shopify/polaris';
import { StyledNavItem } from './Styled';

interface NavlinkProps {
  href: string;
  icon?: any;
  label: string;
  active: boolean;
  locked: boolean;
  sublink?: boolean;
  fillIcon?: boolean;
  hideLabels?: boolean;
  showNewBadge?: boolean;
}
export const Navlink: React.FunctionComponent<NavlinkProps> = ({
  href,
  icon,
  label,
  active,
  locked,
  sublink = false,
  fillIcon = false,
  hideLabels = false,
  showNewBadge = false,
}) => {
  const {
    state: { isSmallScreen },
  } = useGlobalState('common');

  return (
    <Link href={href} passHref legacyBehavior>
      <StyledNavItem
        className={`${locked ? 'locked' : ''} ${active ? 'active' : ''} ${
          sublink && 'sublink'
        } ${fillIcon && 'fillIcon'}`}
        data-test={`sidebar-${(label || '')
          .toLowerCase()
          .split(' ')
          .join('-')}`}
      >
        <div>
          {icon}
          {!hideLabels && <span className='label-container'>{label}</span>}
        </div>
        {showNewBadge && !hideLabels && (
          <Badge tone='info' size='small'>
            NEW!
          </Badge>
        )}
        {!isSmallScreen && (
          <div>{locked && <LockIcon color={colors.blues[5]} />}</div>
        )}
        {active && <span className='active-indicator' />}
      </StyledNavItem>
    </Link>
  );
};

export const NavButton = ({ icon, label, locked }) => {
  return (
    <StyledNavItem
      as='button'
      className={(locked && 'locked') || ''}
      type='button'
      data-test={`sidebar-${(label || '').toLowerCase().split(' ').join('-')}`}
    >
      <div>
        {icon}
        {label}
      </div>
      <div>{locked && <LockIcon color={colors.blues[5]} />}</div>
    </StyledNavItem>
  );
};
