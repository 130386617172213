import colors from 'barn/tokens/colors';

const Rocket = ({ color = colors.greys[2], style = {} }) => {
  return (
    <svg fill='none' width='20' height='20' viewBox='0 0 20 20' style={style}>
      <path
        d='M8.576 7.871l-.325.017-3.477.184-1.909 3.271.204.205 2.453-.756.431-.133.32.32 2.753 2.753.32.32-.133.431-.756 2.452.204.205 3.272-1.909.184-3.477.017-.325.249-.21a32.163 32.163 0 001.864-1.682c1.112-1.118 1.874-2.215 2.32-3.288h0c.404-.97.636-2.129.677-3.489-1.446.036-2.609.256-3.511.633L8.576 7.87zm0 0l.21-.249m-.21.249l.21-.249m0 0a32.186 32.186 0 011.681-1.864M8.785 7.622l1.682-1.864m0 0c1.186-1.18 2.276-1.952 3.266-2.365l-3.266 2.365z'
        stroke={color}
        strokeWidth='1.5'
      />
      <path
        d='M13.94 6.881a.907.907 0 00.667-.274.907.907 0 00.274-.666.907.907 0 00-.274-.667A.907.907 0 0013.94 5a.907.907 0 00-.667.274.908.908 0 00-.274.667c0 .26.092.483.274.666a.907.907 0 00.667.274z'
        fill={color}
      />
    </svg>
  );
};

export default Rocket;
