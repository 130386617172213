import styled from 'styled-components';
import { fontSizes } from 'barn/tokens/typography';
import tokens from 'barn/tokens';
import { responsiveScreenBreakpoint } from 'barn/tokens/misc';

export const StyledNavItem = styled.a`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  border: 0;
  width: 100%;
  cursor: pointer;
  color: ${tokens.colors.greys[2]};
  font-size: ${fontSizes[2]};
  font-weight: 600;
  font-weight: normal;
  padding: ${tokens.space.padding[3]} 0px;
  border-radius: ${tokens.radii[4]};
  transition: 0.2s ease;

  @media (min-height: 800px) {
    padding: ${tokens.space.padding[4]};
  }

  > div {
    display: flex;
    align-items: center;
  }

  .iconColor {
    fill: currentColor;
    stroke: currentColor;
  }

  &:hover {
    background: ${tokens.colors.greys[6]};
    color: ${tokens.colors.greys[1]};
  }

  &.active {
    color: ${tokens.colors.greens[4]};
    background: ${tokens.colors.greys[6]};
    font-weight: 600;

    &:not(.locked) .active-indicator {
      display: block;
      position: absolute;
      right: 14px;
      width: 4px;
      height: 16px;
      background: currentColor;
      border-radius: 40px;
      transition: all 0.2s ease-in;

      @media screen and (max-width: ${tokens.responsiveScreenBreakpoint}) {
        display: none;
      }
    }
  }

  &.sublink {
    font-size: ${fontSizes[1]};
    padding-left: ${tokens.space.padding[10]};
  }

  svg {
    margin: 0 ${tokens.space.margin[3]};
  }

  @media (max-width: ${responsiveScreenBreakpoint}) {
    justify-content: center;
  }
`;
