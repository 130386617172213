import colors from 'barn/tokens/colors';

const SwitchUser = ({ color = colors.greys[2], style = {} }) => {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' style={style}>
      <path
        d='M13.409 13.409a2.048 2.048 0 01-2.046-2.045c0-1.128.918-2.046 2.046-2.046 1.127 0 2.045.918 2.045 2.046a2.048 2.048 0 01-2.045 2.045zM16.886 17.5H9.932a.614.614 0 01-.614-.613v-.41a2.252 2.252 0 012.25-2.25h3.682a2.252 2.252 0 012.25 2.25v.41a.614.614 0 01-.614.613z'
        fill={color}
      />
      <path
        d='M6.59 6.59a2.045 2.045 0 100-4.09 2.045 2.045 0 000 4.09zM8.432 7.41H4.75A2.252 2.252 0 002.5 9.66v.408c0 .34.275.614.614.614h6.954a.614.614 0 00.614-.614V9.66a2.252 2.252 0 00-2.25-2.25z'
        fill='#343946'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.682 3.285l.856 2.737 1.323-.348-.194-.621c1.23.48 2.093 1.599 2.093 2.901h1.376c0-1.801-1.178-3.35-2.866-4.037l.778-.205-.38-1.212-2.986.785z'
        fill='#343946'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.233 14.951l-.067-.389 1.32-.188.469 2.702-2.981.424-.208-1.197 1.177-.167C3.959 15.645 2.5 14 2.5 12.046h1.337c0 1.375 1.01 2.535 2.396 2.905z'
        fill={color}
      />
    </svg>
  );
};

export default SwitchUser;
