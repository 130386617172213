import layout from 'barn/tokens/layout';
import styled from 'styled-components';
import tokens from 'barn/tokens';

const { top, bottom, width } = layout.sidebar;

const StyledSidebar = styled.div<{ isSidebarOpen: boolean }>`
  z-index: var(--p-z-index-3);
  display: flex;
  flex-direction: column;
  position: fixed;
  top: ${top};
  bottom: ${bottom};
  width: ${width};
  background: ${tokens.colors.greys[8]};
  border-right: 1px solid ${tokens.colors.greys[5]};
  min-height: 584px;

  > * {
    margin-bottom: ${tokens.space.margin[4]};
  }

  @media screen and (max-width: ${tokens.responsiveScreenBreakpoint}) {
    width: ${props => (props.isSidebarOpen ? width : '0px')};
  }
`;

export const StyledCTAHolder = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: ${tokens.space.margin[7]};
  flex-shrink: 0;
`;

export const StyledLogoHolder = styled.div<{ isSmallScreen: boolean }>`
  display: flex;
  justify-content: ${props =>
    props.isSmallScreen ? 'space-between' : 'center'};
  align-items: center;
  width: 100%;
  height: 60px;
  gap: ${tokens.space.margin[2]};
  padding: 0 8px;
`;

export const CloseButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
`;

export default StyledSidebar;
