import { v1 as uuidv1 } from 'uuid';
import colors from 'barn/tokens/colors';

const Plans = ({ color = colors.greys[2], style = {} }) => {
  const id = uuidv1();

  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' style={style}>
      <mask
        id={`maskPlans${id}`}
        mask-type='alpha'
        maskUnits='userSpaceOnUse'
        x='4'
        y='2'
        width='12'
        height='16'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M14.5 15.7055L13.4163 14.9344C13.1178 14.7224 12.7225 14.764 12.4698 15.0344L11.5 16.0687L10.5302 15.0344C10.384 14.8784 10.192 14.8 10 14.8C9.808 14.8 9.616 14.8784 9.46975 15.0344L8.5 16.0687L7.53025 15.0344C7.27825 14.7648 6.88225 14.7224 6.58375 14.9344L5.5 15.7055V3.6001H14.5V15.7055ZM15.25 2H4.75C4.336 2 4 2.3576 4 2.79999V17.1998C4 17.495 4.15225 17.7662 4.396 17.9054C4.639 18.0438 4.936 18.0286 5.16625 17.8654L6.90475 16.6294L7.96975 17.7654C8.263 18.0782 8.737 18.0782 9.03025 17.7654L10 16.731L10.9698 17.7654C11.116 17.9222 11.308 17.9998 11.5 17.9998C11.692 17.9998 11.884 17.9222 12.0302 17.7654L13.0953 16.6294L14.8337 17.8654C15.064 18.0286 15.3603 18.0438 15.604 17.9054C15.8477 17.7662 16 17.495 16 17.1998V2.79999C16 2.3576 15.664 2 15.25 2ZM7.75 6.80018H12.25C12.664 6.80018 13 6.44258 13 6.00019C13 5.55779 12.664 5.2002 12.25 5.2002H7.75C7.336 5.2002 7 5.55779 7 6.00019C7 6.44258 7.336 6.80018 7.75 6.80018ZM7.75 13.2001H10C10.414 13.2001 10.75 12.8425 10.75 12.4001C10.75 11.9577 10.414 11.6001 10 11.6001H7.75C7.336 11.6001 7 11.9577 7 12.4001C7 12.8425 7.336 13.2001 7.75 13.2001ZM7.75 10.0001H12.25C12.664 10.0001 13 9.64253 13 9.20014C13 8.75774 12.664 8.40015 12.25 8.40015H7.75C7.336 8.40015 7 8.75774 7 9.20014C7 9.64253 7.336 10.0001 7.75 10.0001Z'
          fill='white'
        />
      </mask>
      <g mask={`url(#maskPlans${id})`}>
        <path
          className='iconColor'
          d='M2.5 2.00024H17.5V18H2.5V2.00024Z'
          fill={color}
        />
      </g>
    </svg>
  );
};

export default Plans;
