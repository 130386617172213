import FullLogo from 'barn/icons/FullLogo';
import Logo from 'barn/icons/Logo';
import { useGlobalState } from 'src/hooks/use-global-state';
import { XIcon } from '@shopify/polaris-icons';
import { Icon } from '@shopify/polaris';
import StyledSidebar, { CloseButton, StyledLogoHolder } from './StyledSidebar';

interface SidebarProps {
  children: React.ReactNode;
}
const Sidebar: React.FunctionComponent<SidebarProps> = ({ children }) => {
  const {
    state: { isSidebarOpen, isSmallScreen },
    actions: { toggleSidebar },
  } = useGlobalState('common');

  return (
    <StyledSidebar data-test='sidebar' isSidebarOpen={isSidebarOpen}>
      <StyledLogoHolder isSmallScreen={isSmallScreen}>
        {isSmallScreen ? <Logo size='mobile' /> : <FullLogo />}
        {isSmallScreen && isSidebarOpen ? (
          <CloseButton onClick={toggleSidebar} type='button'>
            <Icon source={XIcon} />
          </CloseButton>
        ) : null}
      </StyledLogoHolder>
      {children}
    </StyledSidebar>
  );
};

export default Sidebar;
