import { Spinner, Text } from '@shopify/polaris';
import { ProgressBar } from 'barn/components/ProgressBar';
import { format } from 'date-fns';
import useTranslation from 'next-translate/useTranslation';
import { useGlobalState } from 'src/hooks/use-global-state';

export const ConsumptionsMenuItem = () => {
  const { t } = useTranslation('common');

  const {
    state: { consumptionV2 },
  } = useGlobalState('settings');

  if (consumptionV2.isFetching) {
    return <Spinner size='small' />;
  }

  const { webpush, email } = consumptionV2;

  return (
    <>
      {!!webpush.attributionLimit && (
        <>
          <Text variant='bodyMd' as='span'>
            {t('Impressions')} ({webpush.attributionLimitConsumed} /{' '}
            {webpush.attributionLimit} used)
          </Text>

          <ProgressBar
            width='100%'
            max={webpush.attributionLimit}
            value={webpush.attributionLimitConsumed}
          />

          <Text variant='bodyMd' as='span' tone='subdued'>
            {t('Impressions reset at')}{' '}
            {format(new Date(webpush.resetDate), 'LLL d yyyy, p')}
          </Text>
        </>
      )}
      {!!email.attributionLimit && (
        <>
          <Text variant='bodyMd' as='span'>
            {t('Emails')} ({email.attributionLimitConsumed} /{' '}
            {email.attributionLimit} used)
          </Text>

          <ProgressBar
            width='100%'
            max={email.attributionLimit}
            value={email.attributionLimitConsumed}
          />

          <Text variant='bodyMd' as='span' tone='subdued'>
            {t('Emails reset at')}{' '}
            {format(new Date(email.resetDate), 'LLL d yyyy, p')}
          </Text>
        </>
      )}
    </>
  );
};
