import {
  BlockStack,
  Box,
  Button,
  Divider,
  InlineStack,
  Modal,
  Text,
  useBreakpoints,
} from '@shopify/polaris';
import Textarea from 'barn/components/form/textarea';
import useTranslation from 'next-translate/useTranslation';
import { useEffect, useState } from 'react';
import useAuth from 'src/hooks/use-auth';
import { useGlobalState } from 'src/hooks/use-global-state';
import { submitFeedbackAPI } from 'src/lib/api/feedback';
import { FeedbackOwly } from '../FeedbackWidget/FeedbackOwly';
import { FeedbackSuccess } from '../FeedbackWidget/FeedbackSuccess';

export const FeedbackWidget = () => {
  const [isHidden, setIsHidden] = useState(false);

  const { t } = useTranslation('common');
  const {
    state: { isSidebarOpen },
  } = useGlobalState('common');

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [feedback, setFeedback] = useState('');
  const [isSubmissionSuccessful, setIsSubmissionSuccessful] = useState(false);
  const { mdDown } = useBreakpoints();
  const {
    user: {
      website: {
        subdomain,
        shopify_plan: shopifyPlan,
        website_plan: { sku: pushOwlPlan },
      },
    },
  } = useAuth();

  useEffect(() => {
    const handleResize = () => {
      if (typeof window !== 'undefined') {
        setIsHidden(window.innerHeight < 740);
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const submitFeedback = async () => {
    setIsSubmitting(true);
    const { error } = await submitFeedbackAPI({
      feedback,
      subdomain,
      shopifyPlan,
      pushOwlPlan,
    });
    setIsSubmitting(false);
    if (!error) {
      setIsSubmissionSuccessful(true);
    }
  };
  const closeModal = () => {
    setIsModalOpen(false);
    setIsSubmissionSuccessful(false);
    setFeedback('');
  };

  if (mdDown || isHidden) return null;

  return (
    <>
      <Box padding='200'>
        <BlockStack gap='0'>
          <InlineStack gap='200' wrap={false}>
            <FeedbackOwly />
            <Box width='60%' padding='500'>
              {isSidebarOpen && (
                <Button
                  onClick={() => {
                    setIsModalOpen(true);
                  }}
                >
                  {t('Share Feedback')}
                </Button>
              )}
            </Box>
          </InlineStack>
          <Divider />
        </BlockStack>
      </Box>
      <Modal
        open={isModalOpen}
        onClose={closeModal}
        title={t('Feedback')}
        size='small'
        primaryAction={
          isSubmissionSuccessful
            ? null
            : {
                content: t('Submit Feedback'),
                onAction: submitFeedback,
                loading: isSubmitting,
                disabled: !feedback,
              }
        }
        secondaryActions={[
          {
            content: t('Close'),
            onAction: closeModal,
          },
        ]}
      >
        <Modal.Section>
          {isSubmissionSuccessful ? (
            <BlockStack gap='300' inlineAlign='center'>
              <FeedbackSuccess />
              <div style={{ textAlign: 'center' }}>
                <Text as='p' variant='bodyLg'>
                  {t('Thank you!')}
                  <br />
                  {t('Your feedback has been submitted.')}
                </Text>
              </div>
            </BlockStack>
          ) : (
            <Textarea
              name='feedback'
              value={feedback}
              onChange={text => {
                setFeedback(text);
              }}
              fullWidth
            />
          )}
        </Modal.Section>
      </Modal>
    </>
  );
};
