/* eslint-disable react/jsx-wrap-multilines */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Picker from '@emoji-mart/react';
import { Popover, Spinner } from '@shopify/polaris';
import noop from 'lodash.noop';
import SmileyIcon from '../icons/Smiley';

const StyledEmojiButton = styled.button`
  border: 0;
  /* outline: 0; */
  background: transparent;
  cursor: pointer;
`;

interface EmojiPickerProps {
  onSelect?: (data: any) => void;
}
const EmojiPicker: React.FunctionComponent<EmojiPickerProps> = ({
  onSelect = noop,
}) => {
  const [isPopoverVisible, setPopoverVisibility] = useState(false);
  const [emojiData, setEmojiData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchEmojiData = async () => {
    try {
      setIsLoading(true);
      const response = await fetch('/static/emojidata.json');
      const data = await response.json();
      setEmojiData(data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleKeyDown = (event: any) => {
    if (isPopoverVisible && event.key === 'Escape') {
      setPopoverVisibility(false);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown, false);

    return () => {
      document.removeEventListener('keydown', handleKeyDown, false);
    };
  }, [isPopoverVisible]);

  const handlePopover = e => {
    const isShown = !isPopoverVisible;
    if (isShown) {
      fetchEmojiData();
    }
    setPopoverVisibility(isShown);

    // Stop bubble up to Popover which triggers visibility close
    e.stopPropagation();
  };

  return (
    <Popover
      preferredPosition='above'
      preferredAlignment='left'
      zIndexOverride={2147483023}
      active={isPopoverVisible}
      onClose={() => setPopoverVisibility(!isPopoverVisible)}
      fluidContent
      activator={
        <StyledEmojiButton type='button' onClick={handlePopover} tabIndex={0}>
          <SmileyIcon />
        </StyledEmojiButton>
      }
    >
      {isLoading ? (
        <div
          style={{
            minHeight: '150px',
            minWidth: '150px',
            padding: '1em',
          }}
        >
          <Spinner size='small' />
        </div>
      ) : (
        <Picker
          data={emojiData}
          title='Pick your emoji'
          onEmojiSelect={onSelect}
          theme='light'
          style={{ border: 0 }}
          autoFocus
        />
      )}
    </Popover>
  );
};

export default EmojiPicker;
