import { useEffect, useState } from 'react';
import { useGlobalState } from 'src/hooks/use-global-state';

const SUB_ACCOUNT_STATUS_POLL_INTERVAL = 8000; // ms

export const useEmailOnboarding = (props?: {
  canCreateSubAccount: boolean;
}) => {
  const {
    state: { subAccountId },
    actions,
  } = useGlobalState('email');

  const {
    state: { isAuthenticating },
  } = useGlobalState('user');

  const { actions: pricingActions } = useGlobalState('pricingV2');

  const [isCheckingOnboardingStatus, setIsCheckingOnboardingStatus] =
    useState(true);

  const getOrCreateSubAccount = () => {
    if (props?.canCreateSubAccount) {
      return actions.getOrCreateSubAccount();
    }

    return actions.refetchSubAccount();
  };

  const getSubAccountInfo = async () => {
    setIsCheckingOnboardingStatus(true);
    if (!subAccountId) {
      await getOrCreateSubAccount();
      await pricingActions.fetchPricing({
        selectFreeEmailPlanByDefault: true,
      });
    } else {
      await pricingActions.fetchPricing({
        selectFreeEmailPlanByDefault: true,
      });
    }
    setIsCheckingOnboardingStatus(false);
  };

  useEffect(() => {
    let pollInterval;
    if (subAccountId && !actions.isOnboardingCompleted()) {
      pollInterval = setInterval(() => {
        if (document.hasFocus()) {
          actions.refetchSubAccount();
        }
      }, SUB_ACCOUNT_STATUS_POLL_INTERVAL);
    } else {
      clearInterval(pollInterval);
    }

    return () => clearInterval(pollInterval);
  }, [subAccountId]);

  useEffect(() => {
    if (isAuthenticating) return;

    getSubAccountInfo();
  }, [isAuthenticating]);

  return {
    isCheckingOnboardingStatus: isCheckingOnboardingStatus || isAuthenticating,
  };
};
